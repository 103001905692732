import BookmarkLlamaDesktopIcon from '../helpers/customComponents/BookmarkLlamaDesktopIcon'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
export default function SurveySubmitted() {
    return (
        <div className="m-4 h-full ">
            <BookmarkLlamaDesktopIcon />
            <div className="flex h-[50%] flex-col items-center justify-center">
                <CheckCircleIcon
                    className="mb-2 max-h-20 select-none stroke-primary stroke-1"
                    aria-hidden="true"
                />
                <h2 className="select-none text-3xl font-semibold text-primary">
                    Thank you!
                </h2>
                <p className="mt-6 select-none text-center text-lg">
                    We've received your feedback;
                    <br /> this helps us immensely to improve.
                </p>
            </div>
        </div>
    )
}
