import { SubmitHandler, useForm } from 'react-hook-form'
import Navigation from './Navigation'
import { useState, useEffect } from 'react'
import { LockClosedIcon } from '@heroicons/react/24/outline'
import Button, { ButtonType } from '../customComponents/Button'
import { ButtonTheme, NotificationType } from '../constants/enum'
import apiHelper from '../../apiClient/defaultApiClient'
import { useNavigate } from 'react-router-dom'
import { uiText } from '../../uiText/uiText'
import { toastError } from './toastHelper'
import InputPassword from '../customComponents/InputPassword'
import { renderErrorForUpdatePassword } from '../helperFunctions'

interface ResetPasswordInput {
    password: string
}

export default function ResetPassword() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<ResetPasswordInput>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()

    const postResetPassword = async (data: ResetPasswordInput) => {
        setIsLoading(true)
        const params = new URLSearchParams(window.location.search)
        const emailAddress = params.get('email_address') ?? ''
        const resetToken = params.get('reset_token') ?? ''

        try {
            await apiHelper
                .v3ResetPassword({
                    body: {
                        emailAddress: emailAddress,
                        resetToken: resetToken,
                        newPassword: data.password,
                    },
                })
                .then(() => {
                    navigate('/login', {
                        state: {
                            notification: NotificationType.resetPasswordSuccess,
                        },
                    })
                })
        } catch (e: any) {
            toastError(uiText.Notifications.error.resetPassword)
            setIsLoading(false)
        }
    }

    const onSubmit: SubmitHandler<ResetPasswordInput> = async (data) => {
        await postResetPassword(data)
    }

    useEffect(() => {
        // Do not let users access /reset-password without a valid token
        // As this will result in the user NOT being able to reset their password
        if (!location.search) {
            navigate('/')
        }
    })

    return (
        <div className="box-border h-full p-4 sm:px-14 sm:pt-14">
            <Navigation pageTitle="Reset your password" />
            <form
                className="mt-20 flex flex-col items-center justify-center"
                onSubmit={handleSubmit(onSubmit)}
            >
                <LockClosedIcon
                    className="mb-2 max-h-20 select-none stroke-primary stroke-1"
                    aria-hidden="true"
                />
                <h2 className="text-lg font-semibold">Enter a new password</h2>
                <div className="relative mt-8 flex w-72 flex-col sm:w-96">
                    <InputPassword
                        labelText="Password*"
                        register={{
                            ...register('password', {
                                required: true,
                                maxLength: 72,
                            }),
                        }}
                        errors={errors.password}
                        isLoading={isLoading}
                        inputType="reset-password"
                        className="w-full"
                        showPasswordIconClass="bottom-[48%] right-[6%]"
                        customElement={renderErrorForUpdatePassword(
                            errors.password
                        )}
                    />
                </div>
                <Button
                    buttonText={'Reset password'}
                    disabled={isLoading}
                    buttonType={ButtonType.submit}
                    className="mt-10 w-72 sm:w-96"
                    buttonTheme={ButtonTheme.primary}
                />
            </form>
        </div>
    )
}
