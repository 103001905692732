import { useCurrentExtensionState, useCurrentUser } from './Profile'
import {
    CheckCircleIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/24/outline'
import Section from '../helpers/commonComponents/Section'

export default function Account() {
    const { extensionState } = useCurrentExtensionState()
    const { user } = useCurrentUser()

    return (
        <Section className="box-border w-full sm:w-[80%] md:mt-0 md:px-8 lg:ml-0 xl:px-40">
            <div className=" flex w-full flex-col items-center">
                <div className="mb-6 flex w-full flex-col items-center justify-center">
                    <div className="mask-text flex h-14 w-14 items-center justify-center rounded-full bg-default text-4xl font-semibold text-white ">
                        {user?.name[0].toUpperCase()}
                    </div>
                    <p className="mask-text mt-3 w-full truncate text-ellipsis text-center text-lg font-bold">
                        {user?.name}
                    </p>
                    <p className="mask-text w-full truncate text-ellipsis text-center text-lg">
                        {user?.email}
                    </p>
                </div>

                <div
                    className={`flex h-60 w-full flex-col justify-between rounded p-6 sm:h-40 sm:w-96 sm:flex-row sm:items-end md:w-full lg:w-[60%] ${
                        extensionState?.isInstalled
                            ? 'bg-green-100'
                            : 'bg-red-100'
                    }`}
                >
                    {extensionState?.isInstalled ? (
                        <>
                            <CheckCircleIcon
                                className="mb-4 h-24 w-24 text-green-900"
                                aria-hidden="true"
                            />
                            <div className="flex flex-col items-end text-left text-lg text-green-900">
                                <p>extension installed</p>
                                <p>v.{extensionState?.version}</p>
                            </div>
                        </>
                    ) : (
                        <>
                            <ExclamationCircleIcon
                                className="h-20 w-20 text-red-900"
                                aria-hidden="true"
                            />
                            <div className=" flex flex-col items-end text-left text-lg text-red-900">
                                <p>extension</p>
                                <p>not connected</p>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Section>
    )
}
