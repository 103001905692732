/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddSharedFolderAssignmentsRequest,
  ForgotPasswordRequest,
  GetDailyIdentifier200Response,
  GetOrganisationMembers200ResponseInner,
  GetSetupIntent200Response,
  GetSharedFolderAssignments200ResponseInner,
  GetSharedFolderShouldDeleteLocally200Response,
  GetUserProfilePaymentMethods200ResponseInner,
  ModelError,
  Organisation,
  PostLogin200Response,
  PostLoginRequest,
  PostLogout401Response,
  PostSetupIntent201Response,
  PostSharedFolder200Response,
  PostSharedFolderBookmarksRequest,
  PostSharedFolderRequest,
  PostSharedFolderValidatePasswordRequest,
  PostUninstallSurveyRequest,
  PutSharedFolderByIdRequest,
  PutUserByIdRequest,
  ResetPasswordRequest,
  SharedFolder,
  SharedFolderChangelog,
  Signup201Response,
  SignupRequest,
  User,
} from '../models';
import {
    AddSharedFolderAssignmentsRequestFromJSON,
    AddSharedFolderAssignmentsRequestToJSON,
    ForgotPasswordRequestFromJSON,
    ForgotPasswordRequestToJSON,
    GetDailyIdentifier200ResponseFromJSON,
    GetDailyIdentifier200ResponseToJSON,
    GetOrganisationMembers200ResponseInnerFromJSON,
    GetOrganisationMembers200ResponseInnerToJSON,
    GetSetupIntent200ResponseFromJSON,
    GetSetupIntent200ResponseToJSON,
    GetSharedFolderAssignments200ResponseInnerFromJSON,
    GetSharedFolderAssignments200ResponseInnerToJSON,
    GetSharedFolderShouldDeleteLocally200ResponseFromJSON,
    GetSharedFolderShouldDeleteLocally200ResponseToJSON,
    GetUserProfilePaymentMethods200ResponseInnerFromJSON,
    GetUserProfilePaymentMethods200ResponseInnerToJSON,
    ModelErrorFromJSON,
    ModelErrorToJSON,
    OrganisationFromJSON,
    OrganisationToJSON,
    PostLogin200ResponseFromJSON,
    PostLogin200ResponseToJSON,
    PostLoginRequestFromJSON,
    PostLoginRequestToJSON,
    PostLogout401ResponseFromJSON,
    PostLogout401ResponseToJSON,
    PostSetupIntent201ResponseFromJSON,
    PostSetupIntent201ResponseToJSON,
    PostSharedFolder200ResponseFromJSON,
    PostSharedFolder200ResponseToJSON,
    PostSharedFolderBookmarksRequestFromJSON,
    PostSharedFolderBookmarksRequestToJSON,
    PostSharedFolderRequestFromJSON,
    PostSharedFolderRequestToJSON,
    PostSharedFolderValidatePasswordRequestFromJSON,
    PostSharedFolderValidatePasswordRequestToJSON,
    PostUninstallSurveyRequestFromJSON,
    PostUninstallSurveyRequestToJSON,
    PutSharedFolderByIdRequestFromJSON,
    PutSharedFolderByIdRequestToJSON,
    PutUserByIdRequestFromJSON,
    PutUserByIdRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    SharedFolderFromJSON,
    SharedFolderToJSON,
    SharedFolderChangelogFromJSON,
    SharedFolderChangelogToJSON,
    Signup201ResponseFromJSON,
    Signup201ResponseToJSON,
    SignupRequestFromJSON,
    SignupRequestToJSON,
    UserFromJSON,
    UserToJSON,
} from '../models';

export interface AddSharedFolderAssignmentsOperationRequest {
    sharedFolderId: number;
    body?: AddSharedFolderAssignmentsRequest;
}

export interface DeleteOrganisationMemberRequest {
    organisationId: number;
    userId: number;
}

export interface DeleteSharedFolderAssignmentRequest {
    userId: number;
    sharedFolderId: number;
}

export interface DeleteSharedFolderByIdRequest {
    folderID: number;
}

export interface DeviceTrackingRequest {
    deviceId: string;
    event: string;
}

export interface ForgotPasswordOperationRequest {
    body?: ForgotPasswordRequest;
}

export interface GetOrganisationRequest {
    organisationId: number;
}

export interface GetOrganisationMembersRequest {
    organisationId: number;
}

export interface GetSetupIntentRequest {
    id: string;
}

export interface GetSharedFolderAssignmentsRequest {
    sharedFolderId: number;
}

export interface GetSharedFolderBookmarkChangesRequest {
    folderId: number;
    currentVersion?: number;
    rawTreeHash?: string;
    fixedVersion?: number;
}

export interface GetSharedFolderByIdRequest {
    folderID: number;
}

export interface GetSharedFolderShouldDeleteLocallyRequest {
    sharedFolderId: number;
    deviceId: string;
}

export interface PostLoginOperationRequest {
    body?: PostLoginRequest;
}

export interface PostSharedFolderOperationRequest {
    body?: PostSharedFolderRequest;
}

export interface PostSharedFolderBookmarksOperationRequest {
    folderId: number;
    body?: PostSharedFolderBookmarksRequest;
}

export interface PostSharedFolderValidatePasswordOperationRequest {
    folderID: number;
    body?: PostSharedFolderValidatePasswordRequest;
}

export interface PostUninstallSurveyOperationRequest {
    body?: PostUninstallSurveyRequest;
}

export interface PostV2UninstallSurveyRequest {
    body?: PostUninstallSurveyRequest;
}

export interface PutSharedFolderByIdOperationRequest {
    folderID: number;
    body?: PutSharedFolderByIdRequest;
}

export interface PutUserByIdOperationRequest {
    userID: number;
    body?: PutUserByIdRequest;
}

export interface ResetPasswordOperationRequest {
    body?: ResetPasswordRequest;
}

export interface SignupOperationRequest {
    body?: SignupRequest;
}

export interface V3AddSharedFolderAssignmentsRequest {
    sharedFolderId: number;
    body?: AddSharedFolderAssignmentsRequest;
}

export interface V3DeleteOrganisationMemberRequest {
    organisationId: number;
    userId: number;
}

export interface V3DeleteSharedFolderAssignmentRequest {
    userId: number;
    sharedFolderId: number;
}

export interface V3DeviceTrackingRequest {
    deviceId: string;
    event: string;
}

export interface V3ForgotPasswordRequest {
    body?: ForgotPasswordRequest;
}

export interface V3GetOrganisationRequest {
    organisationId: number;
}

export interface V3GetOrganisationMembersRequest {
    organisationId: number;
}

export interface V3GetSetupIntentRequest {
    id: string;
}

export interface V3GetSharedFolderAssignmentsRequest {
    sharedFolderId: number;
}

export interface V3GetSharedFolderShouldDeleteLocallyRequest {
    sharedFolderId: number;
    deviceId: string;
}

export interface V3PostSharedFolderRequest {
    body?: PostSharedFolderRequest;
}

export interface V3ResetPasswordRequest {
    body?: ResetPasswordRequest;
}

export interface V3SignupRequest {
    body?: SignupRequest;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create Shared Folder
     */
    async addSharedFolderAssignmentsRaw(requestParameters: AddSharedFolderAssignmentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling addSharedFolderAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/users`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSharedFolderAssignmentsRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Shared Folder
     */
    async addSharedFolderAssignments(requestParameters: AddSharedFolderAssignmentsOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.addSharedFolderAssignmentsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteOrganisationMemberRaw(requestParameters: DeleteOrganisationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling deleteOrganisationMember.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteOrganisationMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organisation/{organisation_id}/user/{user_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteOrganisationMember(requestParameters: DeleteOrganisationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOrganisationMemberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteSharedFolderAssignmentRaw(requestParameters: DeleteSharedFolderAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling deleteSharedFolderAssignment.');
        }

        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling deleteSharedFolderAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteSharedFolderAssignment(requestParameters: DeleteSharedFolderAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSharedFolderAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     * Delete Shared Folder By ID
     */
    async deleteSharedFolderByIdRaw(requestParameters: DeleteSharedFolderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.folderID === null || requestParameters.folderID === undefined) {
            throw new runtime.RequiredError('folderID','Required parameter requestParameters.folderID was null or undefined when calling deleteSharedFolderById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folderID}`.replace(`{${"folderID"}}`, encodeURIComponent(String(requestParameters.folderID))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Shared Folder By ID
     */
    async deleteSharedFolderById(requestParameters: DeleteSharedFolderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteSharedFolderByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deviceTrackingRaw(requestParameters: DeviceTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling deviceTracking.');
        }

        if (requestParameters.event === null || requestParameters.event === undefined) {
            throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling deviceTracking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/device/{device_id}/{event}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deviceTracking(requestParameters: DeviceTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deviceTrackingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async forgotPasswordRaw(requestParameters: ForgotPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async forgotPassword(requestParameters: ForgotPasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.forgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async getDailyIdentifierRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetDailyIdentifier200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/daily-identifier`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetDailyIdentifier200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getDailyIdentifier(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetDailyIdentifier200Response> {
        const response = await this.getDailyIdentifierRaw(initOverrides);
        return await response.value();
    }

    /**
     * Your GET endpoint
     */
    async getHealthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<object>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/health`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     * Your GET endpoint
     */
    async getHealth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<object> {
        const response = await this.getHealthRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create Organisation
     */
    async getOrganisationRaw(requestParameters: GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organisation>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organisation/{organisation_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationFromJSON(jsonValue));
    }

    /**
     * Create Organisation
     */
    async getOrganisation(requestParameters: GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organisation> {
        const response = await this.getOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated Users Organisation
     */
    async getOrganisationMembersRaw(requestParameters: GetOrganisationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrganisationMembers200ResponseInner>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling getOrganisationMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/organisation/{organisation_id}/users`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganisationMembers200ResponseInnerFromJSON));
    }

    /**
     * Get Authenticated Users Organisation
     */
    async getOrganisationMembers(requestParameters: GetOrganisationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrganisationMembers200ResponseInner>> {
        const response = await this.getOrganisationMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated User
     */
    async getProfileRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Get Authenticated User
     */
    async getProfile(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<User> {
        const response = await this.getProfileRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async getSetupIntentRaw(requestParameters: GetSetupIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSetupIntent200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getSetupIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/setupintent/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSetupIntent200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSetupIntent(requestParameters: GetSetupIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSetupIntent200Response> {
        const response = await this.getSetupIntentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSharedFolderAssignmentsRaw(requestParameters: GetSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSharedFolderAssignments200ResponseInner>>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling getSharedFolderAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/users`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSharedFolderAssignments200ResponseInnerFromJSON));
    }

    /**
     */
    async getSharedFolderAssignments(requestParameters: GetSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSharedFolderAssignments200ResponseInner>> {
        const response = await this.getSharedFolderAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Shared Folder Bookmark changes
     */
    async getSharedFolderBookmarkChangesRaw(requestParameters: GetSharedFolderBookmarkChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SharedFolderChangelog>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling getSharedFolderBookmarkChanges.');
        }

        const queryParameters: any = {};

        if (requestParameters.currentVersion !== undefined) {
            queryParameters['current-version'] = requestParameters.currentVersion;
        }

        if (requestParameters.rawTreeHash !== undefined) {
            queryParameters['raw-tree-hash'] = requestParameters.rawTreeHash;
        }

        if (requestParameters.fixedVersion !== undefined) {
            queryParameters['fixed-version'] = requestParameters.fixedVersion;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folder-id}/bookmarks`.replace(`{${"folder-id"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SharedFolderChangelogFromJSON(jsonValue));
    }

    /**
     * Get Shared Folder Bookmark changes
     */
    async getSharedFolderBookmarkChanges(requestParameters: GetSharedFolderBookmarkChangesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SharedFolderChangelog> {
        const response = await this.getSharedFolderBookmarkChangesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Shared Folder By ID
     */
    async getSharedFolderByIdRaw(requestParameters: GetSharedFolderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SharedFolder>> {
        if (requestParameters.folderID === null || requestParameters.folderID === undefined) {
            throw new runtime.RequiredError('folderID','Required parameter requestParameters.folderID was null or undefined when calling getSharedFolderById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folderID}`.replace(`{${"folderID"}}`, encodeURIComponent(String(requestParameters.folderID))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SharedFolderFromJSON(jsonValue));
    }

    /**
     * Get Shared Folder By ID
     */
    async getSharedFolderById(requestParameters: GetSharedFolderByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SharedFolder> {
        const response = await this.getSharedFolderByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getSharedFolderShouldDeleteLocallyRaw(requestParameters: GetSharedFolderShouldDeleteLocallyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSharedFolderShouldDeleteLocally200Response>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling getSharedFolderShouldDeleteLocally.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling getSharedFolderShouldDeleteLocally.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['device_id'] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{shared_folder_id}/should-delete-locally`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSharedFolderShouldDeleteLocally200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async getSharedFolderShouldDeleteLocally(requestParameters: GetSharedFolderShouldDeleteLocallyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSharedFolderShouldDeleteLocally200Response> {
        const response = await this.getSharedFolderShouldDeleteLocallyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated User Shared Folders
     */
    async getSharedFoldersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<SharedFolder>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/profile/shared-folders`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SharedFolderFromJSON));
    }

    /**
     * Get Authenticated User Shared Folders
     */
    async getSharedFolders(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<SharedFolder>> {
        const response = await this.getSharedFoldersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated User
     */
    async getUserProfilePaymentMethodsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetUserProfilePaymentMethods200ResponseInner>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/profile/payment-methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetUserProfilePaymentMethods200ResponseInnerFromJSON));
    }

    /**
     * Get Authenticated User
     */
    async getUserProfilePaymentMethods(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetUserProfilePaymentMethods200ResponseInner>> {
        const response = await this.getUserProfilePaymentMethodsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Login
     */
    async postLoginRaw(requestParameters: PostLoginOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostLogin200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostLoginRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostLogin200ResponseFromJSON(jsonValue));
    }

    /**
     * Login
     */
    async postLogin(requestParameters: PostLoginOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostLogin200Response> {
        const response = await this.postLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logout
     */
    async postLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Logout
     */
    async postLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postLogoutRaw(initOverrides);
    }

    /**
     */
    async postSetupIntentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostSetupIntent201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/profile/setupintent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostSetupIntent201ResponseFromJSON(jsonValue));
    }

    /**
     */
    async postSetupIntent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostSetupIntent201Response> {
        const response = await this.postSetupIntentRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create Shared Folder
     */
    async postSharedFolderRaw(requestParameters: PostSharedFolderOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostSharedFolder200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSharedFolderRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostSharedFolder200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Shared Folder
     */
    async postSharedFolder(requestParameters: PostSharedFolderOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostSharedFolder200Response> {
        const response = await this.postSharedFolderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update Shared Folder Bookmarks
     */
    async postSharedFolderBookmarksRaw(requestParameters: PostSharedFolderBookmarksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.folderId === null || requestParameters.folderId === undefined) {
            throw new runtime.RequiredError('folderId','Required parameter requestParameters.folderId was null or undefined when calling postSharedFolderBookmarks.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folder-id}/bookmarks`.replace(`{${"folder-id"}}`, encodeURIComponent(String(requestParameters.folderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSharedFolderBookmarksRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Shared Folder Bookmarks
     */
    async postSharedFolderBookmarks(requestParameters: PostSharedFolderBookmarksOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSharedFolderBookmarksRaw(requestParameters, initOverrides);
    }

    /**
     * Validate Shared Folder Password
     */
    async postSharedFolderValidatePasswordRaw(requestParameters: PostSharedFolderValidatePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.folderID === null || requestParameters.folderID === undefined) {
            throw new runtime.RequiredError('folderID','Required parameter requestParameters.folderID was null or undefined when calling postSharedFolderValidatePassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folderID}/validate-password`.replace(`{${"folderID"}}`, encodeURIComponent(String(requestParameters.folderID))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSharedFolderValidatePasswordRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Validate Shared Folder Password
     */
    async postSharedFolderValidatePassword(requestParameters: PostSharedFolderValidatePasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSharedFolderValidatePasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postUninstallSurveyRaw(requestParameters: PostUninstallSurveyOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/uninstall-survey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUninstallSurveyRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postUninstallSurvey(requestParameters: PostUninstallSurveyOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postUninstallSurveyRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postV2UninstallSurveyRaw(requestParameters: PostV2UninstallSurveyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v2/uninstall-survey`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostUninstallSurveyRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postV2UninstallSurvey(requestParameters: PostV2UninstallSurveyRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postV2UninstallSurveyRaw(requestParameters, initOverrides);
    }

    /**
     * This Endpoint is here simply as a stub, we don\'t attempt to define the structure of the request coming from postmark in the swagger.
     */
    async postWebhookPostmarkRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/webhook/postmark`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This Endpoint is here simply as a stub, we don\'t attempt to define the structure of the request coming from postmark in the swagger.
     */
    async postWebhookPostmark(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postWebhookPostmarkRaw(initOverrides);
    }

    /**
     * This Endpoint is here simply as a stub, we don\'t attempt to define the structure of the request coming from stripe in the swagger. 
     */
    async postWebhookStripeRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/webhook/stripe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This Endpoint is here simply as a stub, we don\'t attempt to define the structure of the request coming from stripe in the swagger. 
     */
    async postWebhookStripe(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postWebhookStripeRaw(initOverrides);
    }

    /**
     * Update Shared Folder By ID
     */
    async putSharedFolderByIdRaw(requestParameters: PutSharedFolderByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.folderID === null || requestParameters.folderID === undefined) {
            throw new runtime.RequiredError('folderID','Required parameter requestParameters.folderID was null or undefined when calling putSharedFolderById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/shared-folder/{folderID}`.replace(`{${"folderID"}}`, encodeURIComponent(String(requestParameters.folderID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutSharedFolderByIdRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update Shared Folder By ID
     */
    async putSharedFolderById(requestParameters: PutSharedFolderByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putSharedFolderByIdRaw(requestParameters, initOverrides);
    }

    /**
     * Update User By ID
     */
    async putUserByIdRaw(requestParameters: PutUserByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userID === null || requestParameters.userID === undefined) {
            throw new runtime.RequiredError('userID','Required parameter requestParameters.userID was null or undefined when calling putUserById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/user/{userID}`.replace(`{${"userID"}}`, encodeURIComponent(String(requestParameters.userID))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: PutUserByIdRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update User By ID
     */
    async putUserById(requestParameters: PutUserByIdOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.putUserByIdRaw(requestParameters, initOverrides);
    }

    /**
     */
    async resetPasswordRaw(requestParameters: ResetPasswordOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async resetPassword(requestParameters: ResetPasswordOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.resetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async signupRaw(requestParameters: SignupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signup201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Signup201ResponseFromJSON(jsonValue));
    }

    /**
     */
    async signup(requestParameters: SignupOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signup201Response> {
        const response = await this.signupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create Shared Folder
     */
    async v3AddSharedFolderAssignmentsRaw(requestParameters: V3AddSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling v3AddSharedFolderAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder/{shared_folder_id}/users`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddSharedFolderAssignmentsRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create Shared Folder
     */
    async v3AddSharedFolderAssignments(requestParameters: V3AddSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3AddSharedFolderAssignmentsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3DeleteOrganisationMemberRaw(requestParameters: V3DeleteOrganisationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling v3DeleteOrganisationMember.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling v3DeleteOrganisationMember.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/organisation/{organisation_id}/user/{user_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))).replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3DeleteOrganisationMember(requestParameters: V3DeleteOrganisationMemberRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3DeleteOrganisationMemberRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3DeleteSharedFolderAssignmentRaw(requestParameters: V3DeleteSharedFolderAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling v3DeleteSharedFolderAssignment.');
        }

        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling v3DeleteSharedFolderAssignment.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder/{shared_folder_id}/user/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters.userId))).replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3DeleteSharedFolderAssignment(requestParameters: V3DeleteSharedFolderAssignmentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3DeleteSharedFolderAssignmentRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3DeviceTrackingRaw(requestParameters: V3DeviceTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling v3DeviceTracking.');
        }

        if (requestParameters.event === null || requestParameters.event === undefined) {
            throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling v3DeviceTracking.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/v3/device/{device_id}/{event}`.replace(`{${"device_id"}}`, encodeURIComponent(String(requestParameters.deviceId))).replace(`{${"event"}}`, encodeURIComponent(String(requestParameters.event))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3DeviceTracking(requestParameters: V3DeviceTrackingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3DeviceTrackingRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3ForgotPasswordRaw(requestParameters: V3ForgotPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/forgot-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ForgotPasswordRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3ForgotPassword(requestParameters: V3ForgotPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3ForgotPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Create Organisation
     */
    async v3GetOrganisationRaw(requestParameters: V3GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Organisation>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling v3GetOrganisation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/organisation/{organisation_id}`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrganisationFromJSON(jsonValue));
    }

    /**
     * Create Organisation
     */
    async v3GetOrganisation(requestParameters: V3GetOrganisationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Organisation> {
        const response = await this.v3GetOrganisationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated Users Organisation
     */
    async v3GetOrganisationMembersRaw(requestParameters: V3GetOrganisationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetOrganisationMembers200ResponseInner>>> {
        if (requestParameters.organisationId === null || requestParameters.organisationId === undefined) {
            throw new runtime.RequiredError('organisationId','Required parameter requestParameters.organisationId was null or undefined when calling v3GetOrganisationMembers.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/organisation/{organisation_id}/users`.replace(`{${"organisation_id"}}`, encodeURIComponent(String(requestParameters.organisationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetOrganisationMembers200ResponseInnerFromJSON));
    }

    /**
     * Get Authenticated Users Organisation
     */
    async v3GetOrganisationMembers(requestParameters: V3GetOrganisationMembersRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetOrganisationMembers200ResponseInner>> {
        const response = await this.v3GetOrganisationMembersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v3GetSetupIntentRaw(requestParameters: V3GetSetupIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSetupIntent200Response>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling v3GetSetupIntent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/setupintent/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSetupIntent200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async v3GetSetupIntent(requestParameters: V3GetSetupIntentRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSetupIntent200Response> {
        const response = await this.v3GetSetupIntentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v3GetSharedFolderAssignmentsRaw(requestParameters: V3GetSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSharedFolderAssignments200ResponseInner>>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling v3GetSharedFolderAssignments.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder/{shared_folder_id}/users`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSharedFolderAssignments200ResponseInnerFromJSON));
    }

    /**
     */
    async v3GetSharedFolderAssignments(requestParameters: V3GetSharedFolderAssignmentsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSharedFolderAssignments200ResponseInner>> {
        const response = await this.v3GetSharedFolderAssignmentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v3GetSharedFolderShouldDeleteLocallyRaw(requestParameters: V3GetSharedFolderShouldDeleteLocallyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetSharedFolderShouldDeleteLocally200Response>> {
        if (requestParameters.sharedFolderId === null || requestParameters.sharedFolderId === undefined) {
            throw new runtime.RequiredError('sharedFolderId','Required parameter requestParameters.sharedFolderId was null or undefined when calling v3GetSharedFolderShouldDeleteLocally.');
        }

        if (requestParameters.deviceId === null || requestParameters.deviceId === undefined) {
            throw new runtime.RequiredError('deviceId','Required parameter requestParameters.deviceId was null or undefined when calling v3GetSharedFolderShouldDeleteLocally.');
        }

        const queryParameters: any = {};

        if (requestParameters.deviceId !== undefined) {
            queryParameters['device_id'] = requestParameters.deviceId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder/{shared_folder_id}/should-delete-locally`.replace(`{${"shared_folder_id"}}`, encodeURIComponent(String(requestParameters.sharedFolderId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetSharedFolderShouldDeleteLocally200ResponseFromJSON(jsonValue));
    }

    /**
     */
    async v3GetSharedFolderShouldDeleteLocally(requestParameters: V3GetSharedFolderShouldDeleteLocallyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetSharedFolderShouldDeleteLocally200Response> {
        const response = await this.v3GetSharedFolderShouldDeleteLocallyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get Authenticated User
     */
    async v3GetUserProfilePaymentMethodsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetUserProfilePaymentMethods200ResponseInner>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/profile/payment-methods`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetUserProfilePaymentMethods200ResponseInnerFromJSON));
    }

    /**
     * Get Authenticated User
     */
    async v3GetUserProfilePaymentMethods(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetUserProfilePaymentMethods200ResponseInner>> {
        const response = await this.v3GetUserProfilePaymentMethodsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async v3PostSetupIntentRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostSetupIntent201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/profile/setupintent`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostSetupIntent201ResponseFromJSON(jsonValue));
    }

    /**
     */
    async v3PostSetupIntent(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostSetupIntent201Response> {
        const response = await this.v3PostSetupIntentRaw(initOverrides);
        return await response.value();
    }

    /**
     * Create Shared Folder
     */
    async v3PostSharedFolderRaw(requestParameters: V3PostSharedFolderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PostSharedFolder200Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Authorization authentication
        }

        const response = await this.request({
            path: `/v3/shared-folder`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostSharedFolderRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PostSharedFolder200ResponseFromJSON(jsonValue));
    }

    /**
     * Create Shared Folder
     */
    async v3PostSharedFolder(requestParameters: V3PostSharedFolderRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PostSharedFolder200Response> {
        const response = await this.v3PostSharedFolderRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async v3ResetPasswordRaw(requestParameters: V3ResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async v3ResetPassword(requestParameters: V3ResetPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.v3ResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     */
    async v3SignupRaw(requestParameters: V3SignupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Signup201Response>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/v3/signup`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SignupRequestToJSON(requestParameters.body),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => Signup201ResponseFromJSON(jsonValue));
    }

    /**
     */
    async v3Signup(requestParameters: V3SignupRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Signup201Response> {
        const response = await this.v3SignupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
