import { useSearchParams } from 'react-router-dom'
import {
    CHROME_EXTENSION_URL,
    EDGE_EXTENSION_URL,
    FIREFOX_EXTENSION_URL,
} from './constants/constants'
import { FieldError, FieldErrors } from 'react-hook-form'

//React router v6 does not export the type setSearchParams so to avoid this helper
//function taking a single arg of type any we use ReturnType to get access to the type.
type SetURLSearchParams = ReturnType<typeof useSearchParams>[1]
export const setExtensionInstallOpenedParam = (
    setSearchParams: SetURLSearchParams
) => {
    setSearchParams(
        (prev: any) => {
            prev.set('openedInstallLinkAt', `${Date.now()}`)
            return prev
        },
        { replace: true }
    )
}

type Browser = 'Chrome' | 'Edge' | 'Firefox'
export function getBrowser(): Browser {
    const userAgent: string = navigator.userAgent.toLowerCase()
    if (userAgent.includes('chrome') && !userAgent.includes('edg')) {
        return 'Chrome'
    } else if (userAgent.includes('firefox')) {
        return 'Firefox'
    } else if (userAgent.includes('edg')) {
        return 'Edge'
    } else {
        throw new Error(`unsupported browser with user agent ${userAgent}`)
    }
}

export function isSupportedBrowser(): boolean {
    try {
        getBrowser()
        return true
    } catch (e) {
        return false
    }
}

export async function getInstallUrl(): Promise<string> {
    const browser = await getBrowser()
    switch (browser) {
        case 'Chrome':
            return CHROME_EXTENSION_URL
        case 'Firefox':
            return FIREFOX_EXTENSION_URL
        case 'Edge':
            return EDGE_EXTENSION_URL
        default:
            //This shouldn't be possible as getBrowser will throw instead
            throw new Error('unsupported browser')
    }
}

export const renderErrorForUpdatePassword = (error: FieldError | undefined) => {
    return (
        <div className="flex justify-between">
            {error?.type === 'maxLength' && (
                <span
                    role="alert"
                    className="block font-semibold text-red-800 selection:bg-none"
                >
                    Password limited to 72 characters
                </span>
            )}
            {error && error?.type !== 'maxLength' && (
                <span
                    role="alert"
                    className="block font-semibold text-red-800 selection:bg-none"
                >
                    This field is required
                </span>
            )}{' '}
            {!error && (
                <span className="text-base">
                    Must be at least 6 characters long
                </span>
            )}
        </div>
    )
}
