import { ReactMultiEmail } from './ReactMultiEmail/ReactMultiEmail'
import { useState } from 'react'

interface InviteProps {
    emailAddresses: string[] | [] | undefined
    isLoading: boolean
    handleInviteOnChange: (_emails: string[]) => void
}
export default function Invite(props: InviteProps) {
    const [focused, setFocused] = useState(false)
    const { emailAddresses, isLoading, handleInviteOnChange } = props

    return (
        <ReactMultiEmail
            emails={emailAddresses}
            placeholder="example@example.com"
            disabled={isLoading}
            onChange={(_emails: string[]) => {
                handleInviteOnChange(_emails)
            }}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            getLabel={(email, index, removeEmail) => {
                return (
                    <div
                        data-tag
                        key={index}
                        aria-disabled={isLoading}
                        className="py-2 pl-4"
                    >
                        <div className={`mask-text`} data-tag-item>
                            {email}
                        </div>
                        <div
                            data-tag-handle
                            aria-disabled={isLoading}
                            className={`
                         w-10 rounded-full pb-[1.5px] text-center text-2xl font-semibold 
                         leading-4 hover:text-primary active:text-primary
                        ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'}`}
                            onClick={() => {
                                if (!isLoading) {
                                    removeEmail(index)
                                }
                            }}
                        >
                            ×
                        </div>
                    </div>
                )
            }}
        />
    )
}
