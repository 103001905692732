/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostLogin200Response
 */
export interface PostLogin200Response {
    /**
     * 
     * @type {string}
     * @memberof PostLogin200Response
     */
    sessionToken: string;
}

/**
 * Check if a given object implements the PostLogin200Response interface.
 */
export function instanceOfPostLogin200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "sessionToken" in value;

    return isInstance;
}

export function PostLogin200ResponseFromJSON(json: any): PostLogin200Response {
    return PostLogin200ResponseFromJSONTyped(json, false);
}

export function PostLogin200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostLogin200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'sessionToken': json['session-token'],
    };
}

export function PostLogin200ResponseToJSON(value?: PostLogin200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'session-token': value.sessionToken,
    };
}

