import { RadioGroup } from '@headlessui/react'
import BookmarkLlamaDesktopIcon from '../helpers/customComponents/BookmarkLlamaDesktopIcon'
import { useEffect, useState } from 'react'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { AlertType, ButtonTheme } from '../helpers/constants/enum'
import apiHelper from '../apiClient/defaultApiClient'
import Alert from '../helpers/customComponents/Alert'
import { useNavigate } from 'react-router-dom'

interface UninstallSurveyErrors {
    uninstallReasonError: string
    postError: boolean
}

export default function UninstallSurvey() {
    const uninstallReasons = [
        { id: 1, reason: 'Hard or confusing to use' },
        { id: 2, reason: 'Too expensive' },
        { id: 3, reason: 'Tool did not work as expected' },
        { id: 4, reason: 'Too many technical issues' },
        { id: 5, reason: 'Missing functionality' },
    ]
    const [uninstallReason, setUninstallReason] = useState<string>('')
    const [formErrors, setFormErrors] = useState<UninstallSurveyErrors>({
        uninstallReasonError: '',
        postError: false,
    })
    const [additionalFeedback, setAdditionalFeedback] = useState<string>('')
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const params = new URLSearchParams(window.location.search)
    const deviceId = params.get('device_id') ?? ''

    //Run useEffect to post uninstall event
    useEffect(() => {
        apiHelper
            .deviceTrackingRaw({
                deviceId: deviceId,
                event: 'uninstall',
            })
            .catch((e) => {
                //todo: Log directly to sentry as this should not affect the page in anyway
            })
    }, [])

    const postSurvey = async (results: string, deviceId: string) => {
        try {
            setIsLoading(true)
            await apiHelper.postUninstallSurvey({
                body: {
                    survey: results,
                    deviceId: deviceId,
                },
            })
        } catch (e: any) {
            setFormErrors({ ...formErrors, postError: true })
            setIsLoading(false)
        }
    }

    const handleSubmit = async (event: any) => {
        event.preventDefault()
        if (!uninstallReason) {
            setFormErrors({
                ...formErrors,
                uninstallReasonError: 'Oops, please select a reason',
            })
            return
        } else {
            const results = {
                REASON: uninstallReason,
                COMMENT: additionalFeedback,
            }

            const resultsStringified = JSON.stringify(results)
            await postSurvey(resultsStringified, deviceId).then(() => {
                navigate('/submitted')
            })
        }
    }

    const onChangeUninstallReason = (reason: string) => {
        setUninstallReason(reason)
        setFormErrors({ ...formErrors, uninstallReasonError: '' })
    }

    const onChangeAdditionalFeedback = (event: string) => {
        setAdditionalFeedback(event)
    }

    return (
        <>
            <div className="flex h-full flex-col lg:flex-row">
                <div className="m-4 rounded  bg-primary-light p-4 lg:m-8 lg:w-[35%]">
                    <BookmarkLlamaDesktopIcon />
                    <div className="flex flex-col justify-center py-16 lg:py-0">
                        <h2 className="select-none text-center text-2xl font-semibold text-primary lg:mt-56">
                            We're sorry to see you go!
                        </h2>
                        <p className="mt-8 select-none text-center text-lg lg:mx-8">
                            Give us a chance to improve
                            <br /> by leaving us feedback
                        </p>
                    </div>
                </div>
                <div className="flex h-full w-full flex-col items-center lg:w-[65%]">
                    <form
                        onSubmit={handleSubmit}
                        className="mx-6 mt-6 flex flex-col items-center lg:mt-48 xl:mr-40"
                    >
                        {formErrors.postError && (
                            <Alert
                                alertType={AlertType.failure}
                                showAlert
                                alertText={
                                    'Oops, something went wrong! Please try again later'
                                }
                                className="mb-6"
                            />
                        )}
                        <RadioGroup
                            value={uninstallReason}
                            onChange={onChangeUninstallReason}
                            disabled={isLoading}
                        >
                            <RadioGroup.Label className="select-none text-lg font-bold">
                                Why are you uninstalling Bookmark Llama?
                            </RadioGroup.Label>
                            <div className="mt-6">
                                {uninstallReasons.map((reason) => (
                                    <RadioGroup.Option
                                        key={reason.id}
                                        value={reason.reason}
                                        className={({ active }) =>
                                            `${active ? 'font-bold' : ''}
                  relative flex cursor-pointer rounded-lg py-3 text-lg font-semibold focus:outline-none `
                                        }
                                    >
                                        {({ checked }) => (
                                            <>
                                                <div className="flex items-center">
                                                    {checked ? (
                                                        <div className="mr-3 h-6 w-6 rounded-full border-4 border-primary" />
                                                    ) : (
                                                        <div className="mr-3 h-6 w-6 rounded-full border border-slate-400" />
                                                    )}
                                                    {reason.reason}
                                                </div>
                                            </>
                                        )}
                                    </RadioGroup.Option>
                                ))}
                            </div>
                            <p className="h-4 font-semibold text-red-800">
                                {formErrors.uninstallReasonError
                                    ? formErrors.uninstallReasonError
                                    : ' '}
                            </p>
                        </RadioGroup>

                        <label
                            className="my-4 text-lg font-bold"
                            htmlFor="uninstall-input"
                        >
                            Anything else you'd like us to know?&nbsp;
                            <span className="font-semibold italic">
                                (optional)
                            </span>
                        </label>
                        <input
                            type="text"
                            id="uninstall-input"
                            disabled={isLoading}
                            onChange={(e) => {
                                onChangeAdditionalFeedback(e.target.value)
                            }}
                            className={`focus-visible:invalid:bg-red-5 h-10 w-full rounded-t border-b-2 border-default bg-background pl-2 pr-2 invalid:border-red-800 invalid:text-red-800 focus-visible:border-primary focus-visible:bg-primary-light focus-visible:outline-none focus-visible:invalid:border-red-800 sm:w-96`}
                        />

                        <Button
                            buttonText="Send feedback"
                            buttonTheme={ButtonTheme.primary}
                            buttonType={ButtonType.submit}
                            className="mb-16 mt-20 w-full sm:max-w-max sm:px-24"
                            disabled={isLoading}
                        />
                    </form>
                </div>
            </div>
        </>
    )
}
