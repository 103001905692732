import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
interface ShowPasswordIconProps {
    show: boolean
    showPassword: () => void
    className: string
    disabled?: boolean
}

export default function ShowPasswordIcon(props: ShowPasswordIconProps) {
    const { show, showPassword, className, disabled } = props
    return (
        <label className={`${className}`}>
            <input
                onClick={showPassword}
                tabIndex={0}
                type="checkbox"
                className="peer cursor-pointer opacity-0"
                disabled={disabled}
            />
            {show ? (
                <EyeIcon
                    className="m-0 h-6 w-6 cursor-pointer rounded-sm ring-primary peer-focus-visible:ring-2"
                    aria-hidden="true"
                />
            ) : (
                <EyeSlashIcon
                    className="m-0 h-6 w-6 cursor-pointer rounded-sm ring-primary peer-focus-visible:ring-2"
                    aria-hidden="true"
                />
            )}
        </label>
    )
}
