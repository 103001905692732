import googleIcon from '../../assets/google-icon.png'
import microsoftIcon from '../../assets/microsoft-logo.png'

export function GoogleIcon() {
    return <img src={googleIcon} className="h-6 mx-4" />
}

export function MicrosoftIcon() {
    return <img src={microsoftIcon} className="h-6 mx-4" />
}
