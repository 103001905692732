/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookmarkNode } from './BookmarkNode';
import {
    BookmarkNodeFromJSON,
    BookmarkNodeFromJSONTyped,
    BookmarkNodeToJSON,
} from './BookmarkNode';

/**
 * 
 * @export
 * @interface PostSharedFolderBookmarksRequest
 */
export interface PostSharedFolderBookmarksRequest {
    /**
     * 
     * @type {number}
     * @memberof PostSharedFolderBookmarksRequest
     */
    version: number;
    /**
     * 
     * @type {Array<BookmarkNode>}
     * @memberof PostSharedFolderBookmarksRequest
     */
    bookmarkTree: Array<BookmarkNode>;
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderBookmarksRequest
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderBookmarksRequest
     */
    deviceId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderBookmarksRequest
     */
    rawTreeHash?: string;
}

/**
 * Check if a given object implements the PostSharedFolderBookmarksRequest interface.
 */
export function instanceOfPostSharedFolderBookmarksRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "bookmarkTree" in value;
    isInstance = isInstance && "hash" in value;

    return isInstance;
}

export function PostSharedFolderBookmarksRequestFromJSON(json: any): PostSharedFolderBookmarksRequest {
    return PostSharedFolderBookmarksRequestFromJSONTyped(json, false);
}

export function PostSharedFolderBookmarksRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSharedFolderBookmarksRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': json['version'],
        'bookmarkTree': ((json['bookmark_tree'] as Array<any>).map(BookmarkNodeFromJSON)),
        'hash': json['hash'],
        'deviceId': !exists(json, 'device_id') ? undefined : json['device_id'],
        'rawTreeHash': !exists(json, 'raw_tree_hash') ? undefined : json['raw_tree_hash'],
    };
}

export function PostSharedFolderBookmarksRequestToJSON(value?: PostSharedFolderBookmarksRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'bookmark_tree': ((value.bookmarkTree as Array<any>).map(BookmarkNodeToJSON)),
        'hash': value.hash,
        'device_id': value.deviceId,
        'raw_tree_hash': value.rawTreeHash,
    };
}

