import { useState, useEffect, FormEvent } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import BookmarkLlamaDesktopIcon from '../helpers/customComponents/BookmarkLlamaDesktopIcon'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom'
import { PostLoginRequest } from '../apiClient'
import apiHelper from '../apiClient/defaultApiClient'
import { ButtonTheme, NotificationType } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { UNAUTHORIZED_ERROR } from '../helpers/constants/constants'
import Modal from '../helpers/commonComponents/Modal'
import marketingImage from '../assets/marketing.svg'
import hasSessionToken from '../helpers/hooks/hasSessionToken'
import { uiText } from '../uiText/uiText'
import {
    toastError,
    toastInfo,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'
import Input from '../helpers/customComponents/Input'
import InputPassword from '../helpers/customComponents/InputPassword'

interface LoginFormInputs {
    email: string
    password: string
}

export default function Login() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormInputs>()

    const navigate = useNavigate()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [open, setOpen] = useState<boolean>(false)
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState<string>('')
    const [forgotPasswordEmailError, setForgotPasswordEmailError] =
        useState<boolean>(false)
    const [isForgotPasswordLoading, setIsForgotPasswordLoading] =
        useState<boolean>(false)

    useEffect(() => {
        const notificationType = location.state?.notification
        if (notificationType) {
            const currentURL = `${location.pathname}${location.search}`
            if (notificationType === NotificationType.resetPasswordSuccess) {
                toastSuccess(uiText.Notifications.success.resetPassword)
            }
            if (notificationType === NotificationType.unAuthorizedProfile) {
                toastInfo(uiText.Notifications.error.unauthorizedProfile)
            }
            navigate(currentURL, { state: undefined })
        } else return
    }, [])

    const forgotPassword = async (event: FormEvent<HTMLFormElement>) => {
        event?.preventDefault()
        if (forgotPasswordEmailError) return

        if (forgotPasswordEmail) {
            try {
                setIsForgotPasswordLoading(true)
                await apiHelper
                    .v3ForgotPassword({
                        body: { emailAddress: forgotPasswordEmail },
                    })
                    .then(() => {
                        setForgotPasswordEmail('')
                        setForgotPasswordEmailError(false)
                        setIsForgotPasswordLoading(false)
                        setOpen(false)
                        toastSuccess(
                            uiText.Notifications.success.emailToResetPassword
                        )
                    })
            } catch (e: any) {
                setIsForgotPasswordLoading(false)
                setOpen(!open)
                toastError(uiText.Notifications.error.emailToResetPassword)
            }
        } else {
            setForgotPasswordEmailError(true)
        }
    }

    const postLogin = async (data: PostLoginRequest) => {
        try {
            setIsLoading(true)
            await apiHelper
                .postLogin({
                    body: data,
                })
                .then((data) => {
                    localStorage.setItem('sessionToken', data.sessionToken)
                    navigate('/')
                })
        } catch (e: any) {
            if (e.response?.status === UNAUTHORIZED_ERROR) {
                toastError(uiText.Notifications.error.emailOrPasswordIncorrect)
            } else {
                toastError(uiText.Notifications.error.generic)
            }
            setIsLoading(false)
        }
    }

    const onSubmit: SubmitHandler<LoginFormInputs> = (data) => {
        postLogin(data)
    }

    const onChangeForgotPasswordEmail = (e: string) => {
        if (forgotPasswordEmailError) setForgotPasswordEmailError(false)
        setForgotPasswordEmail(e)
    }

    if (hasSessionToken()) return <Navigate to="/" replace />
    return (
        <div className=" lg:h-screen">
            <div className="flex h-full flex-col justify-center lg:flex-row">
                <div className="flex flex-col">
                    <BookmarkLlamaDesktopIcon className={'mx-4 mt-4 lg:mx-5'} />
                    <form
                        onSubmit={handleSubmit(onSubmit)}
                        className="mx-4 flex flex-col items-center lg:mx-12 xl:mx-24"
                    >
                        {/* Email */}
                        <div className="mb-1 flex w-72 flex-col sm:w-96">
                            <h2 className="mt-20 mb-6 select-none text-3xl font-medium">
                                Hello Again!
                            </h2>
                        </div>
                        <Input
                            labelText="Email*"
                            register={register('email', {
                                required: true,
                            })}
                            errors={errors.email}
                            isLoading={isLoading}
                            inputType="login-email"
                            errorText="This field is required"
                        />

                        {/* Password */}
                        <InputPassword
                            labelText="Password*"
                            register={{
                                ...register('password', {
                                    required: true,
                                    maxLength: 72,
                                }),
                            }}
                            showPasswordIconClass="bottom-[51%] right-[6%] "
                            className={'w-72 sm:w-96'}
                            errors={errors.password}
                            isLoading={isLoading}
                            inputType="login-password"
                            customElement={
                                <div className="flex justify-between">
                                    {errors.password ? (
                                        <span
                                            role="alert"
                                            className="block font-semibold text-red-800 selection:bg-none"
                                        >
                                            This field is required
                                        </span>
                                    ) : (
                                        <span className="text-base">
                                            &nbsp;
                                        </span>
                                    )}
                                    <input
                                        disabled={isLoading}
                                        type="button"
                                        className="mt-1 cursor-pointer self-end rounded-md px-2 font-bold text-primary ring-primary hover:bg-primary-light focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring-2"
                                        onClick={() => setOpen(!open)}
                                        value="Forgot password?"
                                    />
                                </div>
                            }
                        />

                        <Button
                            buttonText={'Log in'}
                            disabled={isLoading}
                            buttonType={ButtonType.submit}
                            className="mt-8 w-72 sm:w-96"
                            buttonTheme={ButtonTheme.primary}
                        />
                    </form>
                    <Link
                        to={'/register'}
                        className="mt-2 self-center rounded-md px-2 ring-primary hover:bg-primary-light focus-visible:rounded-sm  focus-visible:outline-none focus-visible:ring-2"
                    >
                        <span className="inline-block font-semibold">
                            Don't have an account?&nbsp;
                            <span className="font-bold text-primary">
                                Sign up
                            </span>
                        </span>
                    </Link>
                </div>
                <div className="hidden relative mr-5 mt-5 mb-5 w-full grow rounded-xl bg-primary-light lg:block">
                    <h2 className="mt-32 ml-24 select-none text-3xl font-semibold text-primary">
                        The simplest way to share bookmarks
                    </h2>
                    <div className="flex w-full justify-center">
                        <img
                            className="mt-10 md:w-[w-full] lg:w-[65%] min-[1550px]:w-[60%]"
                            src={marketingImage}
                        />
                    </div>
                </div>
            </div>
            <Modal
                open={open}
                setOpen={setOpen}
                modalTitle="Forgot your password? No problem!"
                modalText="Enter your email address, and we'll send an email with steps to reset
        your password."
                onSubmit={(e: FormEvent<HTMLFormElement>) => forgotPassword(e)}
                primaryButton={
                    <Button
                        buttonType={ButtonType.submit}
                        buttonText="Reset password"
                        buttonTheme={ButtonTheme.primary}
                        disabled={isForgotPasswordLoading}
                        className="px-8"
                    />
                }
                secondaryButton={
                    <Button
                        buttonType={ButtonType.button}
                        buttonText="Cancel"
                        buttonTheme={ButtonTheme.tertiary}
                        disabled={isForgotPasswordLoading}
                        className="mt-2 px-8 sm:mr-2 sm:mt-0"
                        onClick={() => {
                            setForgotPasswordEmail('')
                            setForgotPasswordEmailError(false)
                            setOpen(false)
                        }}
                    />
                }
                children={
                    <div className="my-8 flex w-full flex-col items-start">
                        <label className={`ml-2 font-semibold`} htmlFor="email">
                            Email*
                        </label>
                        <input
                            onChange={(e) =>
                                onChangeForgotPasswordEmail(e.target.value)
                            }
                            type="email"
                            aria-invalid={
                                forgotPasswordEmailError ? 'true' : 'false'
                            }
                            className={`h-12 w-full rounded-t border-b-2 border-default bg-background px-2 invalid:border-red-800 invalid:text-red-800 focus-visible:border-primary focus-visible:bg-primary-light focus-visible:outline-none focus-visible:invalid:border-red-800 focus-visible:invalid:bg-red-50 ${
                                forgotPasswordEmailError &&
                                'bg-50-red border-red-800 text-red-800 focus-visible:border-red-800 focus-visible:bg-red-50 focus-visible:outline-none'
                            }`}
                            disabled={isForgotPasswordLoading}
                        />
                        {forgotPasswordEmailError ? (
                            <span
                                role="alert"
                                className="font-semibold text-red-800 selection:bg-none"
                            >
                                This field is required
                            </span>
                        ) : (
                            <span
                                role="alert"
                                className="text-base text-slate-900"
                            >
                                &nbsp;
                            </span>
                        )}
                    </div>
                }
            />
        </div>
    )
}
