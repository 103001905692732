import { useEffect, useState } from 'react'
import {
    getBookmarks,
    bookmarks,
    shareNewFolder,
} from '../extensionCommunication/messager'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import Section from '../helpers/commonComponents/Section'
import Alert from '../helpers/customComponents/Alert'
import {
    AlertType,
    ButtonTheme,
    FolderOwnershipValues,
    ShareType,
    StripeRedirectResponse,
} from '../helpers/constants/enum'
import {
    useExtensionState,
    useOrganisation,
    useUser,
} from '../dashboard/Controller'

import { useNavigate } from 'react-router-dom'
import { NotificationType } from '../helpers/constants/enum'
import { FormValues, FormValueErrors } from '../helpers/constants/types'
import {
    ONE_USER_TO_ADD,
    ZERO_USER_TO_ADD,
    CREATOR,
} from '../helpers/constants/constants'
import { PaymentModal } from '../helpers/commonComponents/PaymentModal'
import apiHelper from '../apiClient/defaultApiClient'
import {
    GetOrganisationMembers200ResponseInner,
    GetUserProfilePaymentMethods200ResponseInner,
} from '../apiClient'
import ShareRadioButtons from './ShareRadioButtons'
import Modal from '../helpers/commonComponents/Modal'
import FolderOwnerShipRadioButtons from './FolderOwnershipRadioButtons'
import BookmarksSection from './BookmarksSection'
import EncryptFolder from './EncryptFolder'
import Invite from '../helpers/commonComponents/Invite'
import BillingPlan from '../helpers/commonComponents/BillingPlan'
import { uiText } from '../uiText/uiText'
import { toastError } from '../helpers/commonComponents/toastHelper'

export default function ShareFolder() {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [formErrors, setFormErrors] = useState<Partial<FormValueErrors>>({})
    const [bookmarks, setBookmarks] = useState<null | bookmarks[]>(null)
    const { extensionState } = useExtensionState()
    const navigate = useNavigate()
    const [openPaymentModal, setOpenPaymentModal] = useState<boolean>(false)
    const [openFakeDoorModal, setOpenFakeDoorModal] = useState<boolean>(false)
    const [hasPaymentMethod, setHasPaymentMethod] = useState<boolean>(false)
    const [paymentMethod, setPaymentMethod] = useState<
        GetUserProfilePaymentMethods200ResponseInner[] | []
    >([])
    const [organisationMembers, setOrganisationMembers] = useState<
        GetOrganisationMembers200ResponseInner[] | []
    >([])
    const { organisation } = useOrganisation()
    const numberOfOrganisationMembers = organisationMembers?.length
    const [formValues, setFormValues] = useState<FormValues>({
        localFolderId: '',
        encryptionPassword: null,
        emailAddresses: [],
        userIds: [],
        shareOption: null,
        folderOwnership: '',
    })
    const { user } = useUser()

    // Remove creator so that billing will not incorrectly show user being charged twice
    const filteredEmailAddressesToRemoveCreator =
        formValues.emailAddresses?.filter((email: string) => {
            return email.toLowerCase() !== user?.email
        })

    const numberOfEmailAddressesToAdd =
        filteredEmailAddressesToRemoveCreator?.length || 0

    // Remove non member email addresses so that billing will not show user
    // being incorrectly charged for members that are already on the org plan
    const nonMemberEmailAddresses = formValues.emailAddresses?.filter(
        (email: string) => {
            const member = organisationMembers.find(
                (member) => member.email === email.toLowerCase()
            )
            return !member
        }
    )

    const isEnterpriseFolder =
        formValues.folderOwnership === FolderOwnershipValues.organisation

    const isShareByInviteFolder = formValues.shareOption === ShareType.share

    const showAddPaymentMethod =
        filteredEmailAddressesToRemoveCreator &&
        numberOfEmailAddressesToAdd > ONE_USER_TO_ADD &&
        !hasPaymentMethod &&
        !isEnterpriseFolder &&
        isShareByInviteFolder

    const isEnterpriseOrIsPremiumFolder =
        isEnterpriseFolder ||
        (hasPaymentMethod && numberOfEmailAddressesToAdd > ONE_USER_TO_ADD)

    const retrieveBookmarks = async () => {
        const response = await getBookmarks()
        const filteredResponse = response?.filter(
            (bookmark) => !bookmark.isShared
        )
        setBookmarks(filteredResponse)
    }

    const getOrganisationUsers = async (organisationId: number) => {
        const response = await apiHelper
            .getOrganisationMembers({ organisationId: organisationId })
            .catch(() => {
                toastError(
                    uiText.Notifications.error.retrieveOrganisationMembers
                )
            })
        if (response) setOrganisationMembers(response)
    }

    const getPaymentMethod = async () => {
        const response = await apiHelper
            .getUserProfilePaymentMethods()
            .catch(() => {})
        if (response) {
            setPaymentMethod(response)
            if (response.length > 0 && !hasPaymentMethod) {
                setHasPaymentMethod(true)
            }
        }
    }

    const getFormValuesFromURL = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const shareOptionFromURL = queryParams.get('option')
        if (shareOptionFromURL) {
            const emailAddressesFromURL = queryParams.get('emails')
            const localFolderIdFromURL = queryParams.get('folder')
            const encryptionPasswordFromURL = queryParams.get('password')
            const folderOwnershipFromURL = queryParams.get('ownership')
            const userIdsFromURL = queryParams.get('user_ids')

            const redirectStatus = queryParams.get('redirect_status')

            if (shareOptionFromURL) {
                setFormValues({
                    emailAddresses: emailAddressesFromURL
                        ? JSON.parse(decodeURIComponent(emailAddressesFromURL))
                        : [],
                    localFolderId: localFolderIdFromURL
                        ? JSON.parse(decodeURIComponent(localFolderIdFromURL))
                        : '',
                    shareOption: shareOptionFromURL
                        ? JSON.parse(decodeURIComponent(shareOptionFromURL))
                        : null,
                    encryptionPassword: encryptionPasswordFromURL
                        ? JSON.parse(
                              decodeURIComponent(encryptionPasswordFromURL)
                          )
                        : null,
                    folderOwnership: folderOwnershipFromURL
                        ? JSON.parse(decodeURIComponent(folderOwnershipFromURL))
                        : '',
                    userIds: userIdsFromURL
                        ? JSON.parse(decodeURIComponent(userIdsFromURL))
                        : [],
                })
            }

            // Redirect status is set by Stripe
            if (
                redirectStatus === StripeRedirectResponse.requiresPaymentMethod
            ) {
                toastError(uiText.Notifications.error.addPaymentMethod)
            }
        } else return
    }

    useEffect(() => {
        if (extensionState?.authenticatedUserId) retrieveBookmarks()
    }, [extensionState])

    useEffect(() => {
        // Handle redirect from Stripe
        const queryParams = new URLSearchParams(window.location.search)
        const redirectStatus = queryParams.get('redirect_status')

        if (!formValues.shareOption) {
            getFormValuesFromURL()
            if (
                redirectStatus === StripeRedirectResponse.success &&
                !hasPaymentMethod
            ) {
                setOpenPaymentModal(true)
            }
        }

        if (!hasPaymentMethod && redirectStatus === null) getPaymentMethod()
    }, [hasPaymentMethod])

    useEffect(() => {
        if (organisation) {
            getOrganisationUsers(organisation.id)
        }
    }, [organisation])

    const handleShareOptionSelect = (value: ShareType) => {
        if (value === ShareType.link) {
            setOpenFakeDoorModal(true)
        } else {
            setFormErrors({})
            setFormValues({ ...formValues, shareOption: value })
        }
    }

    const handleFolderOwnershipSelect = (value: FolderOwnershipValues) => {
        setFormErrors({ ...formErrors, folderOwnership: '' })
        setFormValues({ ...formValues, folderOwnership: value })
    }

    const handleBookmarkFolderSelect = (value: string) => {
        setFormValues({ ...formValues, localFolderId: value })
        setFormErrors({ ...formErrors, localFolderId: '' })
    }

    const handleSetEncryptionPassword = (value: string) => {
        setFormValues({
            ...formValues,
            encryptionPassword: value,
        })
        setFormErrors({
            ...formErrors,
            encryptionPassword: '',
        })
    }

    const handleInviteOnChange = (_emails: string[]) => {
        setFormValues({
            ...formValues,
            emailAddresses: _emails,
        })
        setFormErrors({
            ...formErrors,
            emailAddresses: '',
        })
    }

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        await validateForm()
    }

    const shareFolder = async () => {
        const organisationID = isEnterpriseFolder ? organisation?.id : null
        const invitees = isShareByInviteFolder ? formValues.emailAddresses : []
        const encryptionPassword = formValues.encryptionPassword

        await shareNewFolder(
            formValues.localFolderId,
            encryptionPassword,
            invitees,
            organisationID ?? undefined
        )
            .then(() => {
                navigate('/', {
                    state: {
                        notification: isShareByInviteFolder
                            ? NotificationType.sharedFolderSuccess
                            : NotificationType.syncedFolderSuccess,
                    },
                })
            })
            .catch(() => {
                setIsLoading(false)
                toastError(uiText.Notifications.error.shareFolder)
            })
    }

    const validateForm = async () => {
        const errors: Partial<FormValueErrors> = {}
        if (showAddPaymentMethod) {
            setOpenPaymentModal(true)
            return
        }
        if (!formValues.localFolderId) {
            errors.localFolderId = "You'll need to choose a folder to continue"
        }

        if (
            isShareByInviteFolder &&
            formValues.emailAddresses?.length === ZERO_USER_TO_ADD
        ) {
            errors.emailAddresses =
                "You'll need to add someone to invite, otherwise sync your folder"
        }

        if (!formValues.folderOwnership && organisation) {
            errors.folderOwnership =
                'Let us know who the folder is for to continue'
        }
        setFormErrors(errors)

        if (Object.keys(errors).length === 0) {
            setIsLoading(true)
            shareFolder()
        }
    }

    return (
        <form
            className=" box-border flex h-[90%] w-full flex-col items-center justify-between p-0 pt-10 lg:px-40 lg:pt-14 xl:px-80"
            onSubmit={handleSubmit}
        >
            <div className="w-full">
                <ShareRadioButtons
                    isLoading={isLoading}
                    shareOption={formValues.shareOption}
                    formErrorShareOption={formErrors.shareOption}
                    handleShareOptionSelect={handleShareOptionSelect}
                />

                {formValues.shareOption && (
                    <>
                        {organisation && (
                            <FolderOwnerShipRadioButtons
                                formErrorFolderOwnershipOption={
                                    formErrors.folderOwnership
                                }
                                isLoading={isLoading}
                                organisation={organisation}
                                folderOwnershipOption={
                                    formValues.folderOwnership
                                }
                                handleFolderOwnershipSelect={
                                    handleFolderOwnershipSelect
                                }
                            />
                        )}

                        <BookmarksSection
                            isLoading={isLoading}
                            bookmarks={bookmarks}
                            extensionState={extensionState}
                            localFolderId={formValues.localFolderId}
                            formErrorsLocalFolderId={formErrors.localFolderId}
                            handleBookmarkFolderSelect={
                                handleBookmarkFolderSelect
                            }
                        />

                        {(isEnterpriseFolder ||
                            import.meta.env.MODE === 'localenv') && (
                            <EncryptFolder
                                isLoading={isLoading}
                                handleSetEncryptionPassword={
                                    handleSetEncryptionPassword
                                }
                                formErrorsEncryptionPassword={
                                    formErrors.encryptionPassword
                                }
                            />
                        )}
                        {isShareByInviteFolder && (
                            <>
                                <Section
                                    sectionTitle="Add to folder"
                                    error={formErrors.emailAddresses}
                                >
                                    <>
                                        <Alert
                                            showAlert
                                            alertType={AlertType.info}
                                            alertText={
                                                isEnterpriseOrIsPremiumFolder
                                                    ? 'Adding users may incur a charge'
                                                    : "You're currently on the free plan which is limited to 2 seats per user"
                                            }
                                            className="mb-4"
                                        />
                                        <p className="m-2 select-none text-lg font-medium text-slate-600">
                                            Invite people to your folder by
                                            email
                                        </p>

                                        <Invite
                                            isLoading={isLoading}
                                            handleInviteOnChange={
                                                handleInviteOnChange
                                            }
                                            emailAddresses={
                                                formValues.emailAddresses
                                            }
                                        />
                                    </>
                                </Section>

                                {(isEnterpriseFolder ||
                                    numberOfEmailAddressesToAdd >
                                        ONE_USER_TO_ADD) && (
                                    <Section>
                                        <BillingPlan
                                            isLoading={isLoading}
                                            isEnterpriseFolder={
                                                isEnterpriseFolder
                                            }
                                            numberOfOrganisationMembers={
                                                numberOfOrganisationMembers
                                            }
                                            numberOfEmailAddressesToAdd={
                                                numberOfEmailAddressesToAdd
                                            }
                                            numberOfNonMemberEmailAddressesToAdd={
                                                nonMemberEmailAddresses?.length ??
                                                0
                                            }
                                            totalNumberOfUsersInOrg={
                                                (nonMemberEmailAddresses?.length ??
                                                    0) +
                                                numberOfOrganisationMembers
                                            }
                                            isBillingForShareFolder={true}
                                        />
                                    </Section>
                                )}
                            </>
                        )}

                        <footer className="mt-20 flex w-full justify-end">
                            <Button
                                buttonText={
                                    showAddPaymentMethod
                                        ? 'Add payment method'
                                        : 'Share'
                                }
                                disabled={isLoading}
                                buttonType={ButtonType.submit}
                                className="mb-16 w-full sm:max-w-max sm:px-24"
                                buttonTheme={ButtonTheme.primary}
                            />
                        </footer>
                    </>
                )}
            </div>

            <PaymentModal
                open={openPaymentModal}
                setOpen={setOpenPaymentModal}
                // Add creator to number of emails
                numberOfEmails={(numberOfEmailAddressesToAdd ?? 0) + CREATOR}
                formValues={formValues}
                // setNotification={setNotification}
                getPaymentMethod={getPaymentMethod}
                hasPaymentMethod={hasPaymentMethod}
            />
            <Modal
                open={openFakeDoorModal}
                setOpen={setOpenFakeDoorModal}
                modalTitle={'Thank you for your interest!'}
                modalText={
                    "We're still evaluating the share link which will allow you share a page of bookmarks via link."
                }
                primaryButton={
                    <Button
                        buttonText="Got it"
                        buttonTheme={ButtonTheme.primary}
                        className="w-full sm:max-w-max sm:px-8"
                        buttonType={ButtonType.button}
                        onClick={() => setOpenFakeDoorModal(!openFakeDoorModal)}
                        onKeyDown={(e: any) => {
                            if (e.code === 13 || e.code === 32)
                                setOpenFakeDoorModal(!openFakeDoorModal)
                        }}
                    />
                }
            />
        </form>
    )
}
