import { useEffect, useState } from 'react'
import { PaymentModal } from '../helpers/commonComponents/PaymentModal'
import {
    AlertType,
    ButtonTheme,
    StripeRedirectResponse,
} from '../helpers/constants/enum'
import Alert from '../helpers/customComponents/Alert'
import {
    useCurrentOrganisation,
    useCurrentSharedFolder,
    useFolderUsers,
    useGetCurrentSharedFolder,
    useGetSharedFolderUsers,
} from './Settings'
import Button from '../helpers/customComponents/Button'
import Section from '../helpers/commonComponents/Section'
import apiHelper from '../apiClient/defaultApiClient'
import {
    FREE_FOLDER_LIMIT,
    ONE_USER_IN_FOLDER,
    ONE_USER_TO_ADD,
    ZERO_USER_TO_ADD,
} from '../helpers/constants/constants'
import {
    GetOrganisationMembers200ResponseInner,
    GetUserProfilePaymentMethods200ResponseInner,
} from '../apiClient/models'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import InviteInput from '../helpers/commonComponents/Invite'
import BillingPlan from '../helpers/commonComponents/BillingPlan'
import { uiText } from '../uiText/uiText'
import {
    toastError,
    toastInfo,
    toastSuccess,
} from '../helpers/commonComponents/toastHelper'

export default function Invite() {
    const [emailAddresses, setEmailAddresses] = useState<string[] | []>([])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [error, setError] = useState<string>('')
    const [open, setOpen] = useState<boolean>(false)
    const { currentFolder } = useCurrentSharedFolder()
    const { folderUsers } = useFolderUsers()
    const { getSharedFolderUsers } = useGetSharedFolderUsers()
    const [paymentMethod, setPaymentMethod] = useState<
        GetUserProfilePaymentMethods200ResponseInner[] | []
    >([])
    const [hasPaymentMethod, setHasPaymentMethod] = useState<boolean>(false)
    const { getSharedFolder } = useGetCurrentSharedFolder()
    const { organisation } = useCurrentOrganisation()
    const [organisationMembers, setOrganisationMembers] = useState<
        GetOrganisationMembers200ResponseInner[] | []
    >([])

    // Get all new email addresses to add to folder - discount the current user and any
    // user that is already added to the folder
    const newEmailAddressesToAdd = emailAddresses?.filter((email: string) => {
        const folderUser = folderUsers.find(
            (user) => user.email === email.toLowerCase()
        )
        return !folderUser
    })

    // Get all non org member email addresses to add for payment - do not count
    // any users that are already members - these should not be calculated in new amount
    const nonMemberEmailAddresses = emailAddresses?.filter((email: string) => {
        const member = organisationMembers.find(
            (member) => member.email === email.toLowerCase()
        )
        return !member
    })

    const numberOfFolderUsers = folderUsers?.length
    const organisationFolderId = currentFolder?.organisationId
    const isPremiumFolder = currentFolder?.isPremium
    const numberOfNewEmailAddressesToAdd = newEmailAddressesToAdd?.length

    // Folder users === 2
    const freeFolderLimitReached =
        !isPremiumFolder &&
        numberOfFolderUsers === FREE_FOLDER_LIMIT &&
        !organisationFolderId

    // Folder users < 2
    const freeFolderSeatAvailable =
        !isPremiumFolder &&
        numberOfFolderUsers === ONE_USER_IN_FOLDER &&
        !organisationFolderId

    // Folder users > 2
    const isFreeLimitExceeded =
        !isPremiumFolder &&
        numberOfNewEmailAddressesToAdd + numberOfFolderUsers >
            FREE_FOLDER_LIMIT &&
        !organisationFolderId

    const renderAlertText = () => {
        // if (
        //     emailAddresses.length > numberOfNewEmailAddressesToAdd &&
        //     !organisationFolderId
        // ) {
        //     return "It looks like you're trying to add users that are already in the folder."
        // }
        if (organisationFolderId) {
            return 'Adding new users to your folder may incur a charge'
        }
        if (isPremiumFolder) {
            return 'Adding new users to your folder will incur a charge'
        }
        if (freeFolderLimitReached && hasPaymentMethod) {
            return 'Adding users will incur a charge'
        }
        if (freeFolderLimitReached && !hasPaymentMethod) {
            return "You've reached your seat limit on the free plan, upgrade your folder to continue sharing your folder"
        }
        if (
            freeFolderSeatAvailable &&
            numberOfNewEmailAddressesToAdd > ONE_USER_TO_ADD &&
            hasPaymentMethod
        ) {
            return "You've just exceeded the free plan limit, this will incur a cost"
        }
        if (freeFolderSeatAvailable && numberOfNewEmailAddressesToAdd > 1) {
            return "You've just exceeded the free plan limit, upgrade to add more than one seat"
        }
        if (freeFolderSeatAvailable) {
            return 'You have one more seat available on the free plan'
        }

        return ''
    }

    const getPaymentMethod = async () => {
        setIsLoading(true)
        const response = await apiHelper
            .getUserProfilePaymentMethods()
            .catch(() => {})
        if (response) {
            setPaymentMethod(response)
            if (response.length > 0 && !hasPaymentMethod) {
                setHasPaymentMethod(true)
            }
            setIsLoading(false)
        }
    }

    const getOrganisationUsers = async (organisationId: number) => {
        const response = await apiHelper
            .getOrganisationMembers({ organisationId: organisationId })
            .catch(() => {
                toastError(
                    uiText.Notifications.error.retrieveOrganisationMembers
                )
            })
        if (response) setOrganisationMembers(response)
    }

    useEffect(() => {
        if (organisation) {
            getOrganisationUsers(organisation.id)
        }
    }, [])

    const getFormValuesFromURL = () => {
        const queryParams = new URLSearchParams(window.location.search)
        const emailAddressesFromURL = queryParams.get('emails')
        const redirectStatus = queryParams.get('redirect_status')
        // Redirect status is set by Stripe
        if (redirectStatus === 'requires_payment_method') {
            toastError(uiText.Notifications.error.addPaymentMethod)
        }
        if (emailAddressesFromURL) {
            const decodedEmailAddresses = emailAddressesFromURL
                ? JSON.parse(decodeURIComponent(emailAddressesFromURL))
                : []
            setEmailAddresses(decodedEmailAddresses)
        }
    }

    useEffect(() => {
        // Handle redirect from Stripe
        const queryParams = new URLSearchParams(window.location.search)
        const redirectStatus = queryParams.get('redirect_status')

        if (emailAddresses.length === ZERO_USER_TO_ADD) {
            getFormValuesFromURL()
            if (
                redirectStatus === StripeRedirectResponse.success &&
                !hasPaymentMethod
            ) {
                setOpen(true)
            }
            setIsLoading(false)
        }
        if (!hasPaymentMethod && redirectStatus === null)
            getPaymentMethod().then(() => {
                setIsLoading(false)
            })
    }, [hasPaymentMethod])

    const postInvites = async () => {
        if (currentFolder) {
            setIsLoading(true)
            apiHelper
                .addSharedFolderAssignments({
                    sharedFolderId: currentFolder.id,
                    body: { emailAddresses: emailAddresses },
                })
                .then(() => {
                    Promise.all([
                        organisation && getOrganisationUsers(organisation.id),
                        getSharedFolderUsers({
                            sharedFolderId: currentFolder.id,
                        }),
                        getSharedFolder({ folderID: currentFolder.id }),
                    ]).then(() => {
                        setIsLoading(false)
                    })
                    setEmailAddresses([])
                    toastSuccess(uiText.Notifications.success.addUsersToFolder)
                })
                .catch(() => {
                    toastError(uiText.Notifications.error.addUsersToFolder)

                    setIsLoading(false)
                })
        } else {
            toastError(uiText.Notifications.error.generic)
        }
    }

    const handleInvites = () => {
        if (emailAddresses.length === ZERO_USER_TO_ADD) {
            setError("Oops, you'll need to add email addresses to continue")
            return
        } else if (isFreeLimitExceeded && !hasPaymentMethod) {
            setOpen(true)
            return
        } else {
            postInvites()
        }
    }

    const handleInviteOnChange = (_emails: string[]) => {
        setError('')
        setEmailAddresses(_emails)
    }

    return (
        <Section className="mt-8 box-border w-full sm:w-96 md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-40">
            <>
                {isLoading ? (
                    <div className="flex w-full items-center justify-center">
                        <SpinnerSvgIcon height="40" width="40" />
                    </div>
                ) : (
                    <div className=" box-border w-full">
                        <div>
                            <Alert
                                alertText={renderAlertText()}
                                alertType={AlertType.info}
                                className="mb-4"
                                showAlert={!isLoading}
                            />

                            <p className="m-2 text-lg font-medium text-slate-600">
                                Invite by email
                            </p>

                            <InviteInput
                                isLoading={isLoading}
                                handleInviteOnChange={handleInviteOnChange}
                                emailAddresses={emailAddresses}
                            />
                            <p className="m-2 h-2 font-medium text-red-800">
                                {error ? error : ''}
                            </p>

                            {(isPremiumFolder ||
                                !!organisationFolderId ||
                                isFreeLimitExceeded) && (
                                <Section>
                                    <BillingPlan
                                        isLoading={isLoading}
                                        isEnterpriseFolder={
                                            !!organisationFolderId
                                        }
                                        numberOfOrganisationMembers={
                                            organisationMembers?.length
                                        }
                                        numberOfEmailAddressesToAdd={
                                            numberOfNewEmailAddressesToAdd ?? 0
                                        }
                                        numberOfNonMemberEmailAddressesToAdd={
                                            nonMemberEmailAddresses?.length ?? 0
                                        }
                                        totalNumberOfUsersInOrg={
                                            (nonMemberEmailAddresses?.length ??
                                                0) + organisationMembers?.length
                                        }
                                        isFreeLimitExceeded={
                                            isFreeLimitExceeded
                                        }
                                        isPremiumFolder={isPremiumFolder}
                                        numberOfFolderUsers={
                                            numberOfFolderUsers ?? 0
                                        }
                                        isBillingForShareFolder={false}
                                    />
                                </Section>
                            )}

                            <div className="flex flex-col md:items-end">
                                <Button
                                    disabled={isLoading}
                                    buttonText={
                                        isFreeLimitExceeded && !hasPaymentMethod
                                            ? 'Add Payment Method'
                                            : 'Add to folder'
                                    }
                                    buttonTheme={ButtonTheme.primary}
                                    className="mt-20 px-8"
                                    onClick={() => {
                                        handleInvites()
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                <PaymentModal
                    open={open}
                    numberOfEmails={
                        (numberOfNewEmailAddressesToAdd ?? 0) +
                        numberOfFolderUsers
                    }
                    setOpen={setOpen}
                    emailAddresses={newEmailAddressesToAdd}
                    hasPaymentMethod={hasPaymentMethod}
                    getPaymentMethod={getPaymentMethod}
                />
            </>
        </Section>
    )
}
