import { Dialog, Transition } from '@headlessui/react'
import { FormEvent, Fragment, useEffect, useRef, useState } from 'react'
import {
    UserGroupIcon,
    ArrowPathIcon,
    XMarkIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/24/outline'
import Button, { ButtonType } from '../customComponents/Button'
import { ButtonTheme, StripeRedirectResponse } from '../constants/enum'
import apiHelper from '../../apiClient/defaultApiClient'
import { loadStripe } from '@stripe/stripe-js/pure'
import { Stripe, StripeElementsOptions } from '@stripe/stripe-js'
import {
    Elements,
    PaymentElement,
    useElements,
    useStripe,
} from '@stripe/react-stripe-js'
import SpinnerSvgIcon from '../icons/SpinnerSvgIcon'
import { FormValues } from '../constants/types'
import { PREMIUM_PRICE } from '../constants/constants'
import { uiText } from '../../uiText/uiText'
import { toastError, toastSuccess } from './toastHelper'

interface StepOneProps {
    onNext: () => void
    numberOfEmails: number
    setOpen: (open: boolean) => void
}

let stripePromise: Promise<Stripe | null> | null = null

function StepOne(props: StepOneProps) {
    const { onNext, numberOfEmails, setOpen } = props
    return (
        <form
            className="w-full select-none"
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
                e.preventDefault()
                onNext()
            }}
        >
            <p className="text-lg font-bold">Premium Plan</p>
            <div className="flex w-full justify-between border-b border-slate-300 py-2 text-lg">
                <p>
                    <b>${PREMIUM_PRICE}</b> per user
                </p>

                <p>
                    <b>{numberOfEmails}</b>{' '}
                    {numberOfEmails > 1 ? 'users' : 'user'} total
                </p>
            </div>
            <div className=" flex items-end justify-end py-2  text-right text-lg">
                <p className="text-5xl text-primary">
                    ${numberOfEmails * PREMIUM_PRICE}
                </p>
                <p>&nbsp;/ month</p>
            </div>

            <div className="mt-8 flex flex-wrap justify-around">
                <div className="flex h-48 w-[47%] flex-col justify-between rounded bg-green-100 p-6 text-green-900 sm:mr-4">
                    <p className="text-6xl ">${PREMIUM_PRICE}</p>
                    <p className="text-right text-lg">
                        per user
                        <br /> per month
                    </p>
                </div>
                <div className="flex h-48 w-[47%] flex-col justify-between rounded bg-blue-100 p-6 text-blue-900">
                    <UserGroupIcon className="h-20 w-20" aria-hidden="true" />
                    <p className="text-right text-lg">
                        collaborate <br /> in your folder
                    </p>
                </div>
                <div className="mt-2 flex h-40 w-full items-end justify-between rounded bg-fuchsia-100 p-6 text-fuchsia-900 sm:mt-5">
                    <ArrowPathIcon className="h-20 w-20" aria-hidden="true" />
                    <p className="text-right text-lg">
                        keep your <br /> team in sync
                    </p>
                </div>
            </div>

            <div className="mt-8 flex flex-col sm:flex-row-reverse sm:items-center">
                <Button
                    buttonText="Next"
                    buttonType={ButtonType.submit}
                    buttonTheme={ButtonTheme.primary}
                    className="px-8"
                />
                <Button
                    buttonText="Cancel"
                    buttonType={ButtonType.button}
                    buttonTheme={ButtonTheme.tertiary}
                    className="mt-2 px-8 sm:mr-2 sm:mt-0"
                    onClick={() => setOpen(false)}
                />
            </div>
        </form>
    )
}

interface StepThreeProps {
    setOpen: (open: boolean) => void
    emailAddresses?: string[] | []
    formValues?: FormValues
    getPaymentMethod: () => void
    hasPaymentMethod?: boolean
}

function StepThree(props: StepThreeProps) {
    const { setOpen, getPaymentMethod, hasPaymentMethod } = props

    const pollForPaymentMethod = async (currentDateAtTimerInitiate: number) => {
        const currentDate = new Date().getTime() / 1000
        const twoMinutes = 120
        // Check if 2 mins have elapsed
        if (currentDate - currentDateAtTimerInitiate >= twoMinutes) {
            setOpen(false)
            toastError(uiText.Notifications.error.addPaymentMethod)
        } else {
            await getPaymentMethod()
        }
    }

    useEffect(() => {
        if (hasPaymentMethod) {
            setOpen(false)
            toastSuccess(uiText.Notifications.success.addPaymentMethod)
        } else {
            const currentDateAtTimerInitiate = new Date().getTime() / 1000
            const timer = setInterval(
                () => pollForPaymentMethod(currentDateAtTimerInitiate),
                5000
            )
            return () => {
                clearInterval(timer)
            }
        }
    }, [hasPaymentMethod])

    return (
        <>
            <h1 className="select-none text-center text-lg font-semibold">
                Just a moment!
            </h1>
            <p className="select-none text-center text-lg">
                We're adding your payment method
            </p>
            <div className="flex h-52 w-full items-center justify-center">
                <SpinnerSvgIcon height="40" width="40" />
            </div>
        </>
    )
}

interface StepTwoProps {
    onPrev: () => void
    setOpen: (open: boolean) => void
    emailAddresses?: string[] | []
    formValues?: FormValues
    onNext: () => void
}

function StepTwo(props: StepTwoProps) {
    const [clientSecret, setClientSecret] = useState<string>()
    const { setOpen, emailAddresses, formValues, onNext } = props

    //create setupIntent
    useEffect(() => {
        apiHelper.v3PostSetupIntent().then((setupIntent) => {
            setClientSecret(setupIntent.clientSecret)
        })
    }, [])

    const options: StripeElementsOptions = {
        clientSecret: clientSecret,
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#4338ca',
                colorBackground: '#ffffff',
                colorText: '#334155',
                colorDanger: '#b91c1c',
                fontFamily: 'Mulish, system-ui, sans-serif',
                spacingUnit: '6px',
                borderRadius: '12px',
                focusBoxShadow: '0 0 0 2px #4338ca',
                fontWeightBold: '700',
                fontSizeSm: '18px',
                fontSize3Xs: '14px',
                fontSizeBase: '18px',
            },
        },
    }

    if (!clientSecret)
        return (
            <div className="flex w-full items-center justify-center">
                <SpinnerSvgIcon height="40" width="40" />
            </div>
        )

    return (
        <>
            <div className="flex w-full justify-end">
                <button
                    className="sm:ml-4"
                    onClick={() => {
                        setOpen(!open)
                    }}
                >
                    <XMarkIcon
                        className="h-8 w-8 rounded-full stroke-1 hover:bg-slate-100 focus:bg-slate-100 focus:outline-none focus:ring-1 focus:ring-slate-500"
                        aria-hidden="true"
                    />
                </button>
            </div>
            <Elements stripe={stripePromise} options={options}>
                <PaymentForm
                    onSuccess={() => {
                        onNext()
                    }}
                    emailAddresses={emailAddresses}
                    formValues={formValues}
                />
            </Elements>
        </>
    )
}
interface PaymentFormProps {
    onSuccess: () => void
    emailAddresses?: string[] | []
    formValues?: FormValues
}

function PaymentForm(props: PaymentFormProps) {
    const { onSuccess, emailAddresses, formValues } = props
    const [errorMessage, setErrorMessage] = useState<string>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const stripe = useStripe()
    const elements = useElements()

    const getRedirectURL = () => {
        const currentUrl = window.location.href
        if (emailAddresses) {
            const emailAddressesEncoded = encodeURIComponent(
                JSON.stringify(emailAddresses)
            )
            const updatedUrl = `${currentUrl}?emails=${emailAddressesEncoded}`
            return updatedUrl
        } else if (formValues) {
            const formValuesCopy = { ...formValues }
            for (const prop in formValuesCopy) {
                if (formValuesCopy.hasOwnProperty(prop)) {
                    const val = formValuesCopy[prop as keyof FormValues]
                    formValuesCopy[prop as keyof FormValues] =
                        encodeURIComponent(JSON.stringify(val)) as any
                }
            }

            const emailAddressesForURL = formValuesCopy.emailAddresses
                ? `emails=${formValuesCopy.emailAddresses}&`
                : ''

            const localFolderIdForURL = formValuesCopy.localFolderId
                ? `folder=${formValuesCopy.localFolderId}&`
                : ''

            const shareOptionForURL = formValuesCopy.shareOption
                ? `option=${formValuesCopy.shareOption}&`
                : ''
            const encryptionPasswordForURL = formValuesCopy.encryptionPassword
                ? `password=${formValuesCopy.encryptionPassword}&`
                : ''
            const folderOwnershipForURL = formValuesCopy.folderOwnership
                ? `ownership=${formValuesCopy.folderOwnership}&`
                : ''
            const userIdsForURL = formValuesCopy.userIds
                ? `user_ids=${formValuesCopy.userIds}&`
                : ''

            const updatedUrl = `${currentUrl}?${emailAddressesForURL}${localFolderIdForURL}${shareOptionForURL}${encryptionPasswordForURL}${folderOwnershipForURL}${userIdsForURL}`
            return updatedUrl
        }
        return currentUrl
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault()
        setIsLoading(true)

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return null
        }

        //Test cards can be found here
        //https://stripe.com/docs/payments/save-and-reuse?platform=web#web-test-the-integration
        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: getRedirectURL(),
            },
            redirect: 'if_required',
        })

        if (error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message)
            setIsLoading(false)
        } else {
            onSuccess()
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
        }
    }

    return (
        <form onSubmit={handleSubmit} className="flex flex-col">
            <PaymentElement />
            {/* Show error message to your customers */}
            {errorMessage && (
                <div className="flex items-center pt-2 text-base text-red-800">
                    <div>
                        <ExclamationCircleIcon
                            className="mr-2 h-8 w-8 text-red-500"
                            aria-hidden="true"
                        />
                    </div>
                    {errorMessage}
                </div>
            )}

            <Button
                buttonText="Add Payment Method"
                buttonTheme={ButtonTheme.primary}
                disabled={!stripe || isLoading}
                buttonType={ButtonType.submit}
                className="mt-20 w-full self-end sm:max-w-max sm:px-8"
            />
        </form>
    )
}

interface PaymentModalProps {
    numberOfEmails: number
    open: boolean
    setOpen: (open: boolean) => void
    emailAddresses?: string[] | []
    formValues?: FormValues
    getPaymentMethod: () => void
    hasPaymentMethod: boolean
}

export function PaymentModal(props: PaymentModalProps) {
    const [step, setStep] = useState<number>(1)
    const {
        numberOfEmails,
        open,
        setOpen,
        emailAddresses,
        formValues,
        getPaymentMethod,
        hasPaymentMethod,
    } = props
    const cancelButtonRef = useRef(null)
    stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY)

    useState(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const redirectStatus = queryParams.get('redirect_status')

        if (
            redirectStatus === StripeRedirectResponse.success &&
            !hasPaymentMethod
        ) {
            setStep(3)
        }
    })

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                initialFocus={cancelButtonRef}
                onClose={() => {}}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative w-full transform overflow-hidden rounded bg-white text-left shadow-xl transition-all sm:my-8 sm:w-2/3 xl:w-1/3">
                                <div className="bg-white p-4 sm:p-10">
                                    <div className="sm:flex sm:items-start">
                                        <div className="w-full">
                                            {step === 1 && (
                                                <StepOne
                                                    onNext={() =>
                                                        setStep(step + 1)
                                                    }
                                                    numberOfEmails={
                                                        numberOfEmails
                                                    }
                                                    setOpen={setOpen}
                                                />
                                            )}
                                            {step === 2 && (
                                                <StepTwo
                                                    onNext={() =>
                                                        setStep(step + 1)
                                                    }
                                                    onPrev={() =>
                                                        setStep(step - 1)
                                                    }
                                                    setOpen={setOpen}
                                                    emailAddresses={
                                                        emailAddresses
                                                    }
                                                    formValues={formValues}
                                                />
                                            )}

                                            {step === 3 && (
                                                <StepThree
                                                    setOpen={setOpen}
                                                    formValues={formValues}
                                                    getPaymentMethod={
                                                        getPaymentMethod
                                                    }
                                                    hasPaymentMethod={
                                                        hasPaymentMethod
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
