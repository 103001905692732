import { useState } from 'react'
import { useForm, SubmitHandler } from 'react-hook-form'
import BookmarkLlamaDesktopIcon from '../helpers/customComponents/BookmarkLlamaDesktopIcon'
import { Link, Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import apiHelper from '../apiClient/defaultApiClient'
import { ResponseError, SignupRequest } from '../apiClient'
import { ButtonTheme } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import marketingImage from '../assets/marketing.svg'
import hasSessionToken from '../helpers/hooks/hasSessionToken'
import { Switch } from '@headlessui/react'
import { uiText } from '../uiText/uiText'
import { toastError } from '../helpers/commonComponents/toastHelper'
import Input from '../helpers/customComponents/Input'
import InputPassword from '../helpers/customComponents/InputPassword'
import {
    GoogleIcon,
    MicrosoftIcon,
} from '../helpers/icons/ThirdPartySignInIcons'
import Modal from '../helpers/commonComponents/Modal'

interface RegistrationFormInputs {
    name: string
    email: string
    password: string
}

export default function Register() {
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<RegistrationFormInputs>()

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [enabled, setEnabled] = useState(true)
    const [openFakeDoorModal, setOpenFakeDoorModal] = useState<boolean>(false)

    const [searchParams] = useSearchParams()
    let navigate = useNavigate()

    const postSignUp = async (data: SignupRequest) => {
        setIsLoading(true)
        const inviteCode = searchParams.get('invite_code') ?? undefined
        const skipStripe =
            searchParams.get('skip_stripe') === 'true' ? true : undefined

        await apiHelper
            .signup({
                body: {
                    ...data,
                    inviteCode: inviteCode,
                    marketingEmailConsent: enabled,
                    skipStripe: skipStripe,
                },
            })
            .then((data) => {
                localStorage.setItem('sessionToken', data.sessionToken)
                navigate('/', { state: { register: true } })
            })
            .catch(async (error) => {
                if (error instanceof ResponseError) {
                    await error.response.json().then((res) => {
                        toastError(
                            res.message === 'email address already in-use'
                                ? uiText.Notifications.error.emailAlreadyInUse
                                : uiText.Notifications.error.generic
                        )
                    })
                    setIsLoading(false)
                }
            })
    }

    const onSubmit: SubmitHandler<RegistrationFormInputs> = (data) => {
        postSignUp({ ...data, marketingEmailConsent: false })
    }

    if (hasSessionToken()) return <Navigate to="/" replace />
    else
        return (
            <div className=" lg:h-screen">
                <div className="flex h-full flex-col justify-center lg:flex-row">
                    <div className="flex flex-col">
                        <BookmarkLlamaDesktopIcon
                            className={'mx-4 mt-4  lg:mx-5'}
                        />
                        <form
                            onSubmit={handleSubmit(onSubmit)}
                            className="mx-4 flex flex-col items-center lg:mx-12 xl:mx-24 "
                        >
                            <div className="mb-1 flex w-72 flex-col items-center sm:w-96 sm:items-start">
                                <h2 className="mt-20 mb-6 text-3xl font-medium">
                                    Create an account
                                </h2>

                                {/* Name */}
                                <Input
                                    labelText="Name*"
                                    register={register('name', {
                                        required: true,
                                    })}
                                    errors={errors.name}
                                    isLoading={isLoading}
                                    inputType="register-name"
                                    errorText="This field is required"
                                />
                            </div>
                            {/* Email */}
                            <Input
                                labelText="Email*"
                                register={register('email', {
                                    required: true,
                                })}
                                errors={errors.email}
                                isLoading={isLoading}
                                inputType="register-email"
                                errorText="This field is required"
                            />
                            {/* Password */}
                            <InputPassword
                                labelText="Password*"
                                register={{
                                    ...register('password', {
                                        required: true,
                                        maxLength: 72,
                                    }),
                                }}
                                showPasswordIconClass="bottom-[51%] right-[6%] "
                                className={'w-72 sm:w-96'}
                                errors={errors.password}
                                isLoading={isLoading}
                                inputType="register-password"
                                customElement={
                                    <>
                                        {errors.password &&
                                            errors.password?.type !==
                                                'maxLength' && (
                                                <span
                                                    role="alert"
                                                    className="block font-semibold text-red-800 selection:bg-none"
                                                >
                                                    This field is required
                                                </span>
                                            )}
                                        {!errors.password && (
                                            <span className="text-base">
                                                Must be at least 6 characters
                                                long
                                            </span>
                                        )}
                                    </>
                                }
                            />
                            <Button
                                buttonText={'Create'}
                                disabled={isLoading}
                                buttonType={ButtonType.submit}
                                className="mt-8 w-72 sm:w-96"
                                buttonTheme={ButtonTheme.primary}
                            />
                            <div>
                                <p className="text-center my-1 font-bold text-lg">
                                    or
                                </p>
                                <div>
                                    <Button
                                        buttonText={'Continue with Google'}
                                        disabled={isLoading}
                                        buttonType={ButtonType.button}
                                        className="w-72 sm:w-96 text-default"
                                        buttonTheme={ButtonTheme.secondaryDark}
                                        buttonIcon={<GoogleIcon />}
                                        onClick={() =>
                                            setOpenFakeDoorModal(
                                                !openFakeDoorModal
                                            )
                                        }
                                    />
                                    <Button
                                        buttonText={'Continue with Microsoft'}
                                        disabled={isLoading}
                                        buttonType={ButtonType.button}
                                        className="w-72 sm:w-96 text-default mt-4"
                                        buttonTheme={ButtonTheme.secondaryDark}
                                        buttonIcon={<MicrosoftIcon />}
                                        onClick={() =>
                                            setOpenFakeDoorModal(
                                                !openFakeDoorModal
                                            )
                                        }
                                    />
                                </div>
                            </div>

                            <Modal
                                open={openFakeDoorModal}
                                setOpen={setOpenFakeDoorModal}
                                modalTitle={'Feature in Progress'}
                                modalText={
                                    'Google and Microsoft logins are under development. In the meantime, you can quickly sign up with your email.'
                                }
                                primaryButton={
                                    <Button
                                        buttonText="Got it"
                                        buttonTheme={ButtonTheme.primary}
                                        className="w-full sm:max-w-max sm:px-8"
                                        buttonType={ButtonType.button}
                                        onClick={() =>
                                            setOpenFakeDoorModal(
                                                !openFakeDoorModal
                                            )
                                        }
                                        onKeyDown={(e: any) => {
                                            if (e.code === 13 || e.code === 32)
                                                setOpenFakeDoorModal(
                                                    !openFakeDoorModal
                                                )
                                        }}
                                    />
                                }
                            />
                        </form>

                        <Switch.Group>
                            <div className="mt-4 flex w-72 items-center self-center sm:w-[340px]">
                                <Switch
                                    checked={enabled}
                                    onChange={setEnabled}
                                    className={`${
                                        enabled ? 'bg-primary' : 'bg-gray-300'
                                    } relative inline-flex h-6 w-11 items-center rounded-full`}
                                    disabled={isLoading}
                                >
                                    <span className="sr-only">
                                        Opt-in for marketing emails
                                    </span>

                                    <span
                                        className={`${
                                            enabled
                                                ? 'translate-x-5 sm:translate-x-6 '
                                                : 'translate-x-1'
                                        } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                    />
                                </Switch>
                                <Switch.Label className="selection-none ml-2 cursor-pointer rounded-md p-[2px] font-semibold hover:bg-primary-light">
                                    Subscribe to product news & updates
                                </Switch.Label>
                            </div>
                        </Switch.Group>
                        <Link
                            to={'/login'}
                            className="mt-2 self-center rounded-md px-2 ring-primary hover:bg-primary-light focus-visible:rounded-sm focus-visible:outline-none focus-visible:ring-2"
                        >
                            <span className="inline-block font-semibold">
                                Already have an account?&nbsp;
                                <span className="font-bold text-primary">
                                    Log in
                                </span>
                            </span>
                        </Link>
                    </div>
                    <div className="hidden mb-5 mr-5 mt-5 w-full grow rounded-xl bg-primary-light lg:block">
                        <h2 className="ml-24 mt-32 select-none text-3xl font-semibold text-primary">
                            The simplest way to share bookmarks
                        </h2>
                        <div className="flex w-full justify-center">
                            <img
                                className="mt-10 md:w-[w-full] lg:w-[65%] min-[1550px]:w-[60%] "
                                src={marketingImage}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
}
