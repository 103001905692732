import BookmarkLlamaDesktopIcon from '../customComponents/BookmarkLlamaDesktopIcon'
import SpinnerSvgIcon from '../icons/SpinnerSvgIcon'

export default function LoadingPage() {
    return (
        <div className="box-border flex h-full flex-col items-center justify-center">
            <BookmarkLlamaDesktopIcon />
            <SpinnerSvgIcon height="60" width="60" className="m-8" />
        </div>
    )
}
