import Section from '../helpers/commonComponents/Section'
import ShowPasswordIcon from '../helpers/customComponents/ShowPasswordIcon'
import { useState } from 'react'

interface EncryptFolderProps {
    isLoading: boolean
    handleSetEncryptionPassword: (encryptionPassword: string) => void
    formErrorsEncryptionPassword: string | null | undefined
}

export default function EncryptFolder(props: EncryptFolderProps) {
    const {
        isLoading,
        formErrorsEncryptionPassword,
        handleSetEncryptionPassword,
    } = props

    const [showPassword, setShowPassword] = useState<boolean>(false)

    return (
        <Section sectionTitle="Encrypt your folder">
            <>
                <p className="select-none text-lg font-medium">
                    Add an encryption password. You'll need to give the password
                    to anyone you invite to the folder so keep it safe.
                </p>
                <div className="relative mt-10">
                    <input
                        type={showPassword ? 'text' : 'password'}
                        minLength={6}
                        placeholder="Password"
                        disabled={isLoading}
                        onChange={(e) => {
                            handleSetEncryptionPassword(e.target.value)
                        }}
                        aria-invalid={
                            formErrorsEncryptionPassword ? 'true' : 'false'
                        }
                        className={`peer relative h-12 w-full rounded-t border-b-2 border-default bg-background px-2 text-lg invalid:border-red-800 invalid:text-red-800 focus-visible:border-primary focus-visible:bg-primary-light focus-visible:outline-none focus-visible:invalid:border-red-800 focus-visible:invalid:bg-red-50 disabled:bg-transparent pr-[42px] sm:pr-[52px] ${
                            formErrorsEncryptionPassword &&
                            'border-red-800 text-red-800 focus-visible:border-red-800 focus-visible:bg-red-50 focus-visible:outline-none'
                        }`}
                    />
                    <div className="h4 m-2">
                        <span className=" text-base text-slate-500">
                            Must be at least 6 characters long
                        </span>
                    </div>
                    <ShowPasswordIcon
                        show={showPassword}
                        showPassword={() => setShowPassword(!showPassword)}
                        className={`absolute bottom-[76%] right-[3%] m-0 h-8 w-8 translate-x-1/2 transform disabled:cursor-not-allowed `}
                        disabled={isLoading}
                    />
                </div>
            </>
        </Section>
    )
}
