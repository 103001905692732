import {
    Link,
    Outlet,
    useMatches,
    useNavigate,
    useOutletContext,
    useParams,
} from 'react-router-dom'
import { FormEvent, Fragment, useEffect, useMemo, useState } from 'react'
import {
    GetSharedFolderByIdRequest,
    Organisation,
    SharedFolder,
    User,
    GetSharedFolderAssignments200ResponseInner,
    GetSharedFolderAssignmentsRequest,
} from '../apiClient'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import {
    useCurrentFolder,
    useExtensionState,
    useGetSharedFolder,
    useOrganisation,
    useUser,
} from '../dashboard/Controller'
import apiHelper from '../apiClient/defaultApiClient'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import Modal from '../helpers/commonComponents/Modal'
import {
    ButtonTheme,
    SettingsMenuItem as MenuItem,
} from '../helpers/constants/enum'
import { extensionState } from '../extensionCommunication/messager'

type ContextTypeUser = { user: User | null }
type ContextTypeCurrentSharedFolder = {
    currentFolder: SharedFolder | null
}
type ContextTypeFolderUsers = {
    folderUsers: GetSharedFolderAssignments200ResponseInner[] | []
}
type ContextTypeGetSharedFolderUsers = {
    getSharedFolderUsers: (
        request: GetSharedFolderAssignmentsRequest
    ) => GetSharedFolderAssignments200ResponseInner
}
type ContextTypeSetMenuItem = {
    setMenuItem: (menuItem: MenuItem) => void
}
type ContextTypeGetCurrentSharedFolder = {
    getSharedFolder: (folderId: GetSharedFolderByIdRequest) => SharedFolder
}
type ContextTypeOrganisation = { organisation: Organisation | null }

type ContextTypeCurrentExtensionState = {
    extensionState: extensionState | null | undefined
}
interface ModalProps {
    modalText: string
    modalTitle: string
}

export default function Settings() {
    const [menuItem, setMenuItem] = useState<MenuItem | null>(null)
    const [folderUsers, setFolderUsers] = useState<
        GetSharedFolderAssignments200ResponseInner[] | [] | null
    >([])
    const [modal, setModal] = useState<Partial<ModalProps>>({})
    const [open, setOpen] = useState<boolean>(false)

    const navigate = useNavigate()
    const { folderId } = useParams()

    const { getSharedFolder } = useGetSharedFolder()
    const { user } = useUser()
    const { currentFolder } = useCurrentFolder()
    const { organisation } = useOrganisation()
    const { extensionState } = useExtensionState()

    const matches = useMatches()
    const inviteMatch = matches.find((object) => object.id === 'invite')
    const stopSharingMatch = matches.find(
        (object) => object.id === 'stop-sharing'
    )
    const membersMatch = matches.find((object) => object.id === 'members')

    const MenuItems = [
        { name: MenuItem.members, url: 'members' },
        { name: MenuItem.invite, url: 'invite' },
        { name: MenuItem.stopSharing, url: 'stop-sharing' },
    ]

    function classNames(...classes: any) {
        return classes.filter(Boolean).join(' ')
    }

    const getSharedFolderUsers = async (
        folderId: GetSharedFolderAssignmentsRequest
    ) => {
        let response = await apiHelper
            .getSharedFolderAssignments(folderId)
            .catch(() => {
                setFolderUsers(null)
            })
        const isUserInFolder = response?.find((id) => id.userId === user?.id)
        if (!isUserInFolder) {
            navigate('/')
        }
        if (response) setFolderUsers(response)
    }

    useEffect(() => {
        if (inviteMatch) {
            setMenuItem(MenuItem.invite)
        } else if (stopSharingMatch) {
            setMenuItem(MenuItem.stopSharing)
        } else if (membersMatch) {
            setMenuItem(MenuItem.members)
        } else {
            setMenuItem(MenuItem.members)
        }
    }, [])

    useEffect(() => {
        const sharedFolderId = Number(folderId)
        getSharedFolderUsers({ sharedFolderId: sharedFolderId })
    }, [])

    return (
        <>
            <div className="mt-6 flex flex-col items-center md:mt-20 md:flex-row md:items-start">
                <Menu
                    as="div"
                    className="relative z-50 inline-block w-full rounded text-left md:hidden"
                >
                    <div className="w-full sm:flex sm:justify-center">
                        <Menu.Button className="text-semibold inline-flex h-12 w-full items-center justify-center rounded border border-primary px-4 py-2 text-lg font-semibold text-primary hover:border-primary-hover hover:bg-indigo-50 hover:bg-opacity-25 focus:outline-none focus-visible:ring-2 focus-visible:ring-primary sm:w-96">
                            {menuItem}
                            <ChevronDownIcon
                                className="-mr-1 ml-2 h-5 w-5 "
                                aria-hidden="true"
                            />
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <div className="absolute w-full sm:flex sm:justify-center">
                            <Menu.Items className=" mt-2 w-full rounded-md  bg-white font-medium shadow focus:outline-none sm:w-96">
                                <div>
                                    {MenuItems.map((item) => {
                                        return (
                                            <Menu.Item
                                                key={`settings-menu-item-${item.name}`}
                                            >
                                                {({ active }) => (
                                                    <Link
                                                        to={item.url}
                                                        className={classNames(
                                                            active &&
                                                                'bg-slate-100 text-slate-900',
                                                            'flex items-center px-4 py-2'
                                                        )}
                                                        onClick={() => {
                                                            setMenuItem(
                                                                item.name
                                                            )
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                )}
                                            </Menu.Item>
                                        )
                                    })}
                                    <Menu.Item>
                                        <button
                                            type="button"
                                            className="flex w-full items-center px-4 py-2 hover:bg-slate-100 hover:text-slate-900"
                                            onClick={() => {
                                                setOpen(!open)
                                                setModal({
                                                    modalText:
                                                        "We're still evaluating the restore feature which will allow you to restore previous versions of your bookmark folder.",
                                                    modalTitle:
                                                        'Thank you for your interest!',
                                                })
                                            }}
                                        >
                                            Restore
                                        </button>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <button
                                            type="button"
                                            className="flex w-full items-center px-4 py-2 hover:bg-slate-100 hover:text-slate-900"
                                            onClick={() => {
                                                setOpen(!open)
                                                setModal({
                                                    modalText:
                                                        "We're still evaluating the permissions feature which will allow you to set permissions for your shared bookmark folder.",
                                                    modalTitle:
                                                        'Thank you for your interest!',
                                                })
                                            }}
                                        >
                                            Permissions
                                        </button>
                                    </Menu.Item>
                                </div>
                            </Menu.Items>
                        </div>
                    </Transition>
                </Menu>
                <nav className=" hidden w-40 flex-col border-r border-slate-900 md:flex">
                    {MenuItems.map((item) => {
                        return (
                            <Link
                                key={`settings-menu-item-${item.name}`}
                                to={item.url}
                                className={`${
                                    menuItem === item.name
                                        ? ' font-semibold text-primary'
                                        : 'font-semibold text-default hover:text-primary-hover'
                                } pb-6 pr-2 text-lg`}
                                onClick={() => setMenuItem(item.name)}
                            >
                                {item.name}
                            </Link>
                        )
                    })}

                    <button
                        type="button"
                        className="pb-6 text-left text-lg font-semibold text-default hover:text-primary-hover"
                        onClick={() => {
                            setOpen(!open)
                            setModal({
                                modalText:
                                    "We're still evaluating the restore feature which will allow you to restore previous versions of your bookmark folder.",
                                modalTitle: 'Thank you for your interest!',
                            })
                        }}
                    >
                        Restore
                    </button>
                    <button
                        type="button"
                        className=" text-left text-lg font-semibold text-default hover:text-primary-hover"
                        onClick={() => {
                            setOpen(!open)
                            setModal({
                                modalText:
                                    "We're still evaluating the permissions feature which will allow you to set permissions for your shared bookmark folder.",
                                modalTitle: 'Thank you for your interest!',
                            })
                        }}
                    >
                        Permissions
                    </button>
                </nav>
                <Outlet
                    context={{
                        user,
                        currentFolder,
                        folderUsers,
                        getSharedFolderUsers,
                        setMenuItem,
                        getSharedFolder,
                        organisation,
                        extensionState,
                    }}
                />
            </div>
            <Modal
                modalTitle={modal?.modalTitle}
                modalText={modal?.modalText}
                open={open}
                setOpen={setOpen}
                onSubmit={(e: FormEvent<HTMLFormElement>) => {
                    e.preventDefault()
                    setOpen(!open)
                }}
                primaryButton={
                    <Button
                        buttonText="Got it"
                        buttonTheme={ButtonTheme.primary}
                        className="w-full sm:max-w-max sm:px-8"
                        buttonType={ButtonType.submit}
                    />
                }
            />
        </>
    )
}
export function useCurrentUser() {
    return useOutletContext<ContextTypeUser>()
}

export function useCurrentOrganisation() {
    return useOutletContext<ContextTypeOrganisation>()
}

export function useCurrentSharedFolder() {
    return useOutletContext<ContextTypeCurrentSharedFolder>()
}

export function useFolderUsers() {
    return useOutletContext<ContextTypeFolderUsers>()
}

export function useGetSharedFolderUsers() {
    return useOutletContext<ContextTypeGetSharedFolderUsers>()
}

export function useSetMenuItem() {
    return useOutletContext<ContextTypeSetMenuItem>()
}

export function useGetCurrentSharedFolder() {
    return useOutletContext<ContextTypeGetCurrentSharedFolder>()
}

export function useCurrentExtensionState() {
    return useOutletContext<ContextTypeCurrentExtensionState>()
}
