import { XMarkIcon } from '@heroicons/react/24/solid'
import {
    XCircleIcon,
    CheckCircleIcon,
    InformationCircleIcon,
} from '@heroicons/react/24/outline'

import toast from 'react-hot-toast'

const customToast = (icon: JSX.Element, message: string) => {
    return toast.custom((t) => (
        <div
            className={`
                ${t.visible ? 'animate-enter' : 'animate-leave'}
             w-72 h-full bg-white shadow-sm rounded-lg pointer-events-auto flex ring-1 ring-default ring-opacity-5 items-center`}
        >
            <div className="flex-1 w-0 p-3">
                <div className="flex items-center">
                    {icon}
                    <div className="ml-3 flex-1 text-default">{message}</div>
                </div>
            </div>
            <div className="h-full">
                <button
                    onClick={() => toast.dismiss(t.id)}
                    type="button"
                    className="w-full h-full p-2 rounded-full flex items-center justify-center hover:bg-slate-100 active:ring-1 active:ring-slate-500"
                >
                    <XMarkIcon
                        className="h-8 w-8 rounded-full"
                        aria-hidden="true"
                    />
                </button>
            </div>
        </div>
    ))
}

export function toastSuccess(message: string) {
    return customToast(
        <CheckCircleIcon
            className="h-8 w-8 rounded-full stroke-green-500 "
            aria-hidden="true"
        />,
        message
    )
}

export function toastError(message: string) {
    return customToast(
        <XCircleIcon
            className="h-8 w-8 rounded-full stroke-red-500 "
            aria-hidden="true"
        />,
        message
    )
}

export function toastInfo(message: string) {
    return customToast(
        <InformationCircleIcon
            className="h-8 w-8 rounded-full stroke-blue-500"
            aria-hidden="true"
        />,
        message
    )
}
