interface getExtensionStateMessage {
    type: 'getExtensionInfo'
}

interface getBookmarksMessage {
    type: 'getBookmarks'
}

interface runSyncEngineMessage {
    type: 'runSyncEngine'
}

interface openLocalFolderMessage {
    type: 'openLocalFolder'
    localFolderId: string
}

interface logoutMessage {
    type: 'logout'
}

interface disableAutoRun {
    type: 'disableAutoRun'
    status: boolean
}

interface setSharedFolderEncryptionPasswordMessage {
    type: 'setSharedFolderEncryptionPassword'
    sharedFolderId: number
    password: string
    passwordSalt: string
}

interface shareNewFolder {
    type: 'shareNewFolder'
    localFolderId: string
    emailAddresses?: string[]
    encryptionPassword: string | null
    organisationId?: number
}

interface authenticateMessage {
    type: 'authenticate'
    sessionToken: string
}

interface bookmarkAPICallMessage {
    type: 'bookmarkAPICall'
    methodName: string
    args: any[]
}

interface localStorageAPICallMessage {
    type: 'localStorageAPICall'
    methodName: string
    args: any[]
}

let hasInitialized = false
const sendMessageToExtension = async (
    message:
        | getExtensionStateMessage
        | authenticateMessage
        | getBookmarksMessage
        | runSyncEngineMessage
        | shareNewFolder
        | logoutMessage
        | setSharedFolderEncryptionPasswordMessage
        | openLocalFolderMessage
        | bookmarkAPICallMessage
        | localStorageAPICallMessage
        | disableAutoRun
): Promise<any> => {
    const missingExtension = new Error('extension not installed')
    const browserIsFirefox = navigator.userAgent
        .toLowerCase()
        .includes('firefox/')
    if (browserIsFirefox) {
        return new Promise<any>(async (resolve, reject) => {
            //content-scripts are not visible immediately normally taking a few ms, as a result we can get
            //a false positive if we call this too early, so we sleep only the first time this function is run
            //to avoid hitting this edge case
            if (!hasInitialized) await new Promise((r) => setTimeout(r, 100))

            hasInitialized = true

            //For firefox because we won't get an error from messaging we need to check if the
            //extension is installed manually. We expose a function from the content script, then
            //we check if it exists
            const hasContentScript: () => {} = (window as any)
                .isExtensionInstalled
            if (!hasContentScript as any) {
                reject(missingExtension)
                return
            }

            //It's possible that the function exists but can't be called due to a dead object error
            //this is caused by the extension being removed but the page not being refreshed, the below
            //catches this case.
            try {
                hasContentScript()
            } catch (err) {
                reject(missingExtension)
                return
            }

            let id: string
            if (crypto.randomUUID) {
                id = crypto.randomUUID()
            } else {
                id = Math.random().toString(32).slice(2)
            }

            window.postMessage(
                {
                    direction: 'from-page-script',
                    message: message,
                    messageId: id,
                },
                '*'
            )
            const cb = (evt: any) => {
                if (
                    evt &&
                    evt.data.direction === 'from-script-page' &&
                    evt.data.messageId === id
                ) {
                    removeSelf()
                    if (evt.data.error) {
                        reject(new Error(evt.data.error))
                        return
                    }

                    if (evt.data?.message?.error) {
                        reject(new Error(evt.data.message.error))
                        return
                    }
                    resolve(evt.data.message)
                }
            }
            const removeSelf = () => {
                window.removeEventListener('message', cb)
            }
            window.addEventListener('message', cb)
        })
    }

    let extId: string
    const browserIsEdge = navigator.userAgent.toLowerCase().includes('edg/')
    const browserIsChrome = navigator.userAgent
        .toLowerCase()
        .includes('chrome/')

    if (browserIsEdge) {
        extId = 'ipnohflnkaljegnpiepbfefnnodmgkmp'
    } else if (browserIsChrome) {
        if (import.meta.env.MODE === 'staging') {
            //we have a different extension for staging
            extId = 'iedhbflfmkbannocdjaembfabhlaideg'
        } else {
            extId = 'mimpfplfhbocogjgjoiklgefbfkcflph'
        }
    } else {
        throw new Error(
            `unsupported browser with userAgent ${navigator.userAgent}`
        )
    }
    return new Promise<any>((resolve, reject) => {
        try {
            if (!chrome.runtime) throw missingExtension
            chrome.runtime.sendMessage(extId, message, (response: any) => {
                let lastError = chrome.runtime.lastError
                if (lastError) {
                    reject(lastError)
                    return
                }
                if (response?.error) {
                    reject(new Error(response.error))
                    return
                }
                resolve(response)
            })
        } catch (err) {
            reject(err)
        }
    })
}

export interface extensionState {
    version: string
    authenticatedUserId: number | null
    isInstalled: boolean
}

export const getExtensionState = async (): Promise<extensionState> => {
    try {
        const response = await sendMessageToExtension({
            type: 'getExtensionInfo',
        })
        return {
            authenticatedUserId: response.isAuthenticated,
            version: response.version,
            isInstalled: true,
        }
    } catch (err) {
        const errMsg = (err as any).message as string | undefined
        if (
            errMsg ===
                'Could not establish connection. Receiving end does not exist.' ||
            errMsg === 'extension not installed'
        ) {
            return {
                authenticatedUserId: null,
                version: '',
                isInstalled: false,
            }
        }
        //unknown error so we throw it
        throw err
    }
}

export const authenticateExtension = async (
    sessionToken: string
): Promise<number | null> => {
    return await sendMessageToExtension({
        type: 'authenticate',
        sessionToken: sessionToken,
    })
}

export interface bookmarks {
    localId: string
    remoteId?: string
    name: string
    isShared: boolean
    hasPasswordSet: boolean
}
export const getBookmarks = async (): Promise<bookmarks[]> => {
    return (
        await sendMessageToExtension({
            type: 'getBookmarks',
        })
    ).bookmarks
}

export const shareNewFolder = async (
    localFolderId: string,
    encryptionPassword: string | null,
    emailAddresses?: string[],
    organisationId?: number
): Promise<void> => {
    await sendMessageToExtension({
        type: 'shareNewFolder',
        localFolderId: localFolderId,
        encryptionPassword: encryptionPassword,
        emailAddresses: emailAddresses,
        organisationId: organisationId,
    })
}

export const runSyncEngine = async (): Promise<void> => {
    await sendMessageToExtension({
        type: 'runSyncEngine',
    })
    return
}

export const logoutExtension = async (): Promise<bookmarks[]> => {
    return await sendMessageToExtension({
        type: 'logout',
    })
}

export const disableAutoRun = async (
    autoRun: boolean
): Promise<bookmarks[]> => {
    return await sendMessageToExtension({
        type: 'disableAutoRun',
        status: autoRun,
    })
}

export const setSharedFolderEncryptionPasswordMessage = async (
    password: string,
    passwordSalt: string,
    sharedFolderId: number
): Promise<bookmarks[]> => {
    return await sendMessageToExtension({
        type: 'setSharedFolderEncryptionPassword',
        password: password,
        passwordSalt: passwordSalt,
        sharedFolderId: sharedFolderId,
    })
}

export const openLocalFolder = async (
    localFolderID: string
): Promise<bookmarks[]> => {
    return await sendMessageToExtension({
        type: 'openLocalFolder',
        localFolderId: localFolderID,
    })
}

if (import.meta.env.MODE !== 'production') {
    ;(window as any).extensionMethods = {
        getExtensionState,
        getBookmarks,
        runSyncEngine,
        disableAutoRun,
        bookmarkAPICall: async (methodName: string, args: any[]) => {
            //This function allows us to call arbitrary methods inside the bookmarks api
            //this is used inside end-to-end testing only and is disabled in production
            return await sendMessageToExtension({
                type: 'bookmarkAPICall',
                methodName: methodName,
                args: args,
            })
        },
        localStorageAPICall: async (methodName: string, args: any[]) => {
            //This function allows us to call arbitrary methods inside the browser.storage.local api
            //this is used inside end-to-end testing only and is disabled in production
            return await sendMessageToExtension({
                type: 'localStorageAPICall',
                methodName: methodName,
                args: args,
            })
        },
    }
}
