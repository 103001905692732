import BookmarkLlamaSvgIcon from '../icons/BookmarkLlamaSvgIcon'

interface BookmarkLlamaDesktopIconProps {
    className?: string
}

export default function BookmarkLlamaDesktopIcon(
    props: BookmarkLlamaDesktopIconProps
) {
    const { className } = props
    return (
        <div className={`${className} flex max-w-max items-center font-logo`}>
            <BookmarkLlamaSvgIcon height="42px" width="32px" className="mt-2" />
            <div>
                <p className="select-none font-bold leading-none">Bookmark</p>
                <p className="select-none text-lg leading-none">Llama</p>
            </div>
        </div>
    )
}
