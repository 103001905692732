interface BrowserSvgIconProps {
    height?: string
    width?: string
    className?: string
}

export function ChromeSvgIcon(props: BrowserSvgIconProps) {
    const { height, width, className } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 190.5 190.5"
            className={className}
        >
            <g transform="translate(90.669 -507.469)">
                <path
                    d="M4.583 650.342c26.304 0 47.627-21.324 47.627-47.628s-21.323-47.628-47.627-47.628-47.627 21.324-47.627 47.628 21.323 47.628 47.627 47.628z"
                    fill="#fff"
                    clipPath="none"
                    mask="none"
                />
                <path
                    d="M-36.664 626.539l-41.24-71.43c-8.362 14.479-12.765 30.904-12.765 47.625s4.401 33.146 12.762 47.625 20.387 26.503 34.868 34.86 30.908 12.755 47.628 12.75l41.24-71.43v-.011c-4.177 7.244-10.188 13.26-17.428 17.443a47.62 47.62 0 0 1-47.632.007 47.62 47.62 0 0 1-17.433-17.437z"
                    fill="#229342"
                    clipPath="none"
                    mask="none"
                />
                <path
                    d="M45.826 626.536l-41.239 71.43c16.72.003 33.146-4.398 47.626-12.757s26.504-20.384 34.863-34.865a95.24 95.24 0 0 0 12.755-47.627c-.003-16.72-4.408-33.145-12.772-47.623H4.58l-.01.007a47.62 47.62 0 0 1 23.819 6.372c7.243 4.179 13.257 10.19 17.439 17.431a47.62 47.62 0 0 1-.001 47.633z"
                    fill="#fbc116"
                    clipPath="none"
                    mask="none"
                />
                <path
                    d="M4.583 640.43c20.824 0 37.705-16.881 37.705-37.706s-16.881-37.705-37.705-37.705-37.705 16.881-37.705 37.705 16.881 37.706 37.705 37.706z"
                    fill="#1a73e8"
                    clipPath="none"
                    mask="none"
                />
                <path
                    d="M4.583 555.097h82.479c-8.358-14.481-20.381-26.507-34.861-34.868a95.23 95.23 0 0 0-47.625-12.76c-16.72.001-33.145 4.404-47.623 12.767a95.23 95.23 0 0 0-34.856 34.872l41.24 71.43.011.006a47.62 47.62 0 0 1-.015-47.633c4.179-7.242 10.193-13.256 17.434-17.436s15.456-6.381 23.818-6.379z"
                    fill="#e33b2e"
                    clipPath="none"
                    mask="none"
                />
            </g>
        </svg>
    )
}

export function EdgeSvgIcon(props: BrowserSvgIconProps) {
    const { height, width, className } = props
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 256"
            width={width}
            height={height}
            className={className}
            {...props}
        >
            <defs>
                <radialGradient
                    id="edge_svg__b"
                    cx={161.8}
                    cy={68.9}
                    r={95.4}
                    gradientTransform="matrix(1 0 0 -.95 0 248.8)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.7} stopOpacity={0} />
                    <stop offset={0.9} stopOpacity={0.5} />
                    <stop offset={1} />
                </radialGradient>
                <radialGradient
                    id="edge_svg__d"
                    cx={-340.3}
                    cy={63}
                    r={143.2}
                    gradientTransform="matrix(.15 -.99 -.8 -.12 176.6 -125.4)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.8} stopOpacity={0} />
                    <stop offset={0.9} stopOpacity={0.5} />
                    <stop offset={1} />
                </radialGradient>
                <radialGradient
                    id="edge_svg__e"
                    cx={113.4}
                    cy={570.2}
                    r={202.4}
                    gradientTransform="matrix(-.04 1 2.13 .08 -1179.5 -106.7)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#35c1f1" />
                    <stop offset={0.1} stopColor="#34c1ed" />
                    <stop offset={0.2} stopColor="#2fc2df" />
                    <stop offset={0.3} stopColor="#2bc3d2" />
                    <stop offset={0.7} stopColor="#36c752" />
                </radialGradient>
                <radialGradient
                    id="edge_svg__f"
                    cx={376.5}
                    cy={568}
                    r={97.3}
                    gradientTransform="matrix(.28 .96 .78 -.23 -303.8 -148.5)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#66eb6e" />
                    <stop offset={1} stopColor="#66eb6e" stopOpacity={0} />
                </radialGradient>
                <linearGradient
                    id="edge_svg__a"
                    x1={63.3}
                    y1={84}
                    x2={241.7}
                    y2={84}
                    gradientTransform="matrix(1 0 0 -1 0 266)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#0c59a4" />
                    <stop offset={1} stopColor="#114a8b" />
                </linearGradient>
                <linearGradient
                    id="edge_svg__c"
                    x1={157.3}
                    y1={161.4}
                    x2={46}
                    y2={40.1}
                    gradientTransform="matrix(1 0 0 -1 0 266)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0} stopColor="#1b9de2" />
                    <stop offset={0.2} stopColor="#1595df" />
                    <stop offset={0.7} stopColor="#0680d7" />
                    <stop offset={1} stopColor="#0078d4" />
                </linearGradient>
            </defs>
            <path
                d="M235.7 195.5a93.7 93.7 0 0 1-10.6 4.7 101.9 101.9 0 0 1-35.9 6.4c-47.3 0-88.5-32.5-88.5-74.3a31.5 31.5 0 0 1 16.4-27.3c-42.8 1.8-53.8 46.4-53.8 72.5 0 74 68.1 81.4 82.8 81.4 7.9 0 19.8-2.3 27-4.6l1.3-.4a128.3 128.3 0 0 0 66.6-52.8 4 4 0 0 0-5.3-5.6Z"
                transform="translate(-4.6 -5)"
                style={{
                    fill: 'url(#edge_svg__a)',
                }}
            />
            <path
                d="M235.7 195.5a93.7 93.7 0 0 1-10.6 4.7 101.9 101.9 0 0 1-35.9 6.4c-47.3 0-88.5-32.5-88.5-74.3a31.5 31.5 0 0 1 16.4-27.3c-42.8 1.8-53.8 46.4-53.8 72.5 0 74 68.1 81.4 82.8 81.4 7.9 0 19.8-2.3 27-4.6l1.3-.4a128.3 128.3 0 0 0 66.6-52.8 4 4 0 0 0-5.3-5.6Z"
                transform="translate(-4.6 -5)"
                style={{
                    isolation: 'isolate',
                    opacity: 0.35,
                    fill: 'url(#edge_svg__b)',
                }}
            />
            <path
                d="M110.3 246.3A79.2 79.2 0 0 1 87.6 225a80.7 80.7 0 0 1 29.5-120c3.2-1.5 8.5-4.1 15.6-4a32.4 32.4 0 0 1 25.7 13 31.9 31.9 0 0 1 6.3 18.7c0-.2 24.5-79.6-80-79.6-43.9 0-80 41.6-80 78.2a130.2 130.2 0 0 0 12.1 56 128 128 0 0 0 156.4 67 75.5 75.5 0 0 1-62.8-8Z"
                transform="translate(-4.6 -5)"
                style={{
                    fill: 'url(#edge_svg__c)',
                }}
            />
            <path
                d="M110.3 246.3A79.2 79.2 0 0 1 87.6 225a80.7 80.7 0 0 1 29.5-120c3.2-1.5 8.5-4.1 15.6-4a32.4 32.4 0 0 1 25.7 13 31.9 31.9 0 0 1 6.3 18.7c0-.2 24.5-79.6-80-79.6-43.9 0-80 41.6-80 78.2a130.2 130.2 0 0 0 12.1 56 128 128 0 0 0 156.4 67 75.5 75.5 0 0 1-62.8-8Z"
                transform="translate(-4.6 -5)"
                style={{
                    opacity: 0.41,
                    fill: 'url(#edge_svg__d)',
                    isolation: 'isolate',
                }}
            />
            <path
                d="M157 153.8c-.9 1-3.4 2.5-3.4 5.6 0 2.6 1.7 5.2 4.8 7.3 14.3 10 41.4 8.6 41.5 8.6a59.6 59.6 0 0 0 30.3-8.3 61.4 61.4 0 0 0 30.4-52.9c.3-22.4-8-37.3-11.3-43.9C228 28.8 182.3 5 132.6 5a128 128 0 0 0-128 126.2c.5-36.5 36.8-66 80-66 3.5 0 23.5.3 42 10a72.6 72.6 0 0 1 30.9 29.3c6.1 10.6 7.2 24.1 7.2 29.5s-2.7 13.3-7.8 19.9Z"
                transform="translate(-4.6 -5)"
                style={{
                    fill: 'url(#edge_svg__e)',
                }}
            />
            <path
                d="M157 153.8c-.9 1-3.4 2.5-3.4 5.6 0 2.6 1.7 5.2 4.8 7.3 14.3 10 41.4 8.6 41.5 8.6a59.6 59.6 0 0 0 30.3-8.3 61.4 61.4 0 0 0 30.4-52.9c.3-22.4-8-37.3-11.3-43.9C228 28.8 182.3 5 132.6 5a128 128 0 0 0-128 126.2c.5-36.5 36.8-66 80-66 3.5 0 23.5.3 42 10a72.6 72.6 0 0 1 30.9 29.3c6.1 10.6 7.2 24.1 7.2 29.5s-2.7 13.3-7.8 19.9Z"
                transform="translate(-4.6 -5)"
                style={{
                    fill: 'url(#edge_svg__f)',
                }}
            />
        </svg>
    )
}

export function FirefoxSvgIcon(props: BrowserSvgIconProps) {
    const { height, width, className } = props
    return (
        <svg
            width={width}
            height={height}
            className={className}
            version="1.1"
            viewBox="0 0 77.42 79.97"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>Firefox Browser logo</title>
            <defs>
                <linearGradient
                    id="a"
                    x1="70.79"
                    x2="6.447"
                    y1="12.39"
                    y2="74.47"
                    gradientTransform="translate(-1.3 -.004086)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff44f" offset=".048" />
                    <stop stopColor="#ffe847" offset=".111" />
                    <stop stopColor="#ffc830" offset=".225" />
                    <stop stopColor="#ff980e" offset=".368" />
                    <stop stopColor="#ff8b16" offset=".401" />
                    <stop stopColor="#ff672a" offset=".462" />
                    <stop stopColor="#ff3647" offset=".534" />
                    <stop stopColor="#e31587" offset=".705" />
                </linearGradient>
                <radialGradient
                    id="b"
                    cx="-7907"
                    cy="-8515"
                    r="80.8"
                    gradientTransform="translate(7974,8524)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ffbd4f" offset=".129" />
                    <stop stopColor="#ffac31" offset=".186" />
                    <stop stopColor="#ff9d17" offset=".247" />
                    <stop stopColor="#ff980e" offset=".283" />
                    <stop stopColor="#ff563b" offset=".403" />
                    <stop stopColor="#ff3750" offset=".467" />
                    <stop stopColor="#f5156c" offset=".71" />
                    <stop stopColor="#eb0878" offset=".782" />
                    <stop stopColor="#e50080" offset=".86" />
                </radialGradient>
                <radialGradient
                    id="c"
                    cx="-7937"
                    cy="-8482"
                    r="80.8"
                    gradientTransform="translate(7974,8524)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#960e18" offset=".3" />
                    <stop stopColor="#b11927" stopOpacity=".74" offset=".351" />
                    <stop
                        stopColor="#db293d"
                        stopOpacity=".343"
                        offset=".435"
                    />
                    <stop
                        stopColor="#f5334b"
                        stopOpacity=".094"
                        offset=".497"
                    />
                    <stop stopColor="#ff3750" stopOpacity="0" offset=".53" />
                </radialGradient>
                <radialGradient
                    id="d"
                    cx="-7927"
                    cy="-8533"
                    r="58.53"
                    gradientTransform="translate(7974,8524)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff44f" offset=".132" />
                    <stop stopColor="#ffdc3e" offset=".252" />
                    <stop stopColor="#ff9d12" offset=".506" />
                    <stop stopColor="#ff980e" offset=".526" />
                </radialGradient>
                <radialGradient
                    id="e"
                    cx="-7946"
                    cy="-8461"
                    r="38.47"
                    gradientTransform="translate(7974,8524)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#3a8ee6" offset=".353" />
                    <stop stopColor="#5c79f0" offset=".472" />
                    <stop stopColor="#9059ff" offset=".669" />
                    <stop stopColor="#c139e6" offset="1" />
                </radialGradient>
                <radialGradient
                    id="f"
                    cx="-7936"
                    cy="-8492"
                    r="20.4"
                    gradientTransform="matrix(.972 -.235 .275 1.138 10090 7834)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#9059ff" stopOpacity="0" offset=".206" />
                    <stop
                        stopColor="#8c4ff3"
                        stopOpacity=".064"
                        offset=".278"
                    />
                    <stop stopColor="#7716a8" stopOpacity=".45" offset=".747" />
                    <stop stopColor="#6e008b" stopOpacity=".6" offset=".975" />
                </radialGradient>
                <radialGradient
                    id="g"
                    cx="-7938"
                    cy="-8518"
                    r="27.68"
                    gradientTransform="translate(7974,8524)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#ffe226" offset="0" />
                    <stop stopColor="#ffdb27" offset=".121" />
                    <stop stopColor="#ffc82a" offset=".295" />
                    <stop stopColor="#ffa930" offset=".502" />
                    <stop stopColor="#ff7e37" offset=".732" />
                    <stop stopColor="#ff7139" offset=".792" />
                </radialGradient>
                <radialGradient
                    id="h"
                    cx="-7916"
                    cy="-8536"
                    r="118.1"
                    gradientTransform="translate(7974,8524)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff44f" offset=".113" />
                    <stop stopColor="#ff980e" offset=".456" />
                    <stop stopColor="#ff5634" offset=".622" />
                    <stop stopColor="#ff3647" offset=".716" />
                    <stop stopColor="#e31587" offset=".904" />
                </radialGradient>
                <radialGradient
                    id="i"
                    cx="-7927"
                    cy="-8523"
                    r="86.5"
                    gradientTransform="matrix(.105 .995 -.653 .069 -4685 8470)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff44f" offset="0" />
                    <stop stopColor="#ffe847" offset=".06" />
                    <stop stopColor="#ffc830" offset=".168" />
                    <stop stopColor="#ff980e" offset=".304" />
                    <stop stopColor="#ff8b16" offset=".356" />
                    <stop stopColor="#ff672a" offset=".455" />
                    <stop stopColor="#ff3647" offset=".57" />
                    <stop stopColor="#e31587" offset=".737" />
                </radialGradient>
                <radialGradient
                    id="j"
                    cx="-7938"
                    cy="-8508"
                    r="73.72"
                    gradientTransform="translate(7974,8524)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff44f" offset=".137" />
                    <stop stopColor="#ff980e" offset=".48" />
                    <stop stopColor="#ff5634" offset=".592" />
                    <stop stopColor="#ff3647" offset=".655" />
                    <stop stopColor="#e31587" offset=".904" />
                </radialGradient>
                <radialGradient
                    id="k"
                    cx="-7919"
                    cy="-8504"
                    r="80.69"
                    gradientTransform="translate(7974,8524)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff44f" offset=".094" />
                    <stop stopColor="#ffe141" offset=".231" />
                    <stop stopColor="#ffaf1e" offset=".509" />
                    <stop stopColor="#ff980e" offset=".626" />
                </radialGradient>
                <linearGradient
                    id="l"
                    x1="70.01"
                    x2="15.27"
                    y1="12.06"
                    y2="66.81"
                    gradientTransform="translate(-1.3 -.004086)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff44f" stopOpacity=".8" offset=".167" />
                    <stop
                        stopColor="#fff44f"
                        stopOpacity=".634"
                        offset=".266"
                    />
                    <stop
                        stopColor="#fff44f"
                        stopOpacity=".217"
                        offset=".489"
                    />
                    <stop stopColor="#fff44f" stopOpacity="0" offset=".6" />
                </linearGradient>
            </defs>
            <g transform="matrix(.9819843 0 0 .9819843 .6974849 .7199239)">
                <path
                    d="m74.62 26.83c-1.684-4.052-5.1-8.427-7.775-9.81a40.27 40.27 0 0 1 3.925 11.76l7e-3 0.065c-4.382-10.92-11.81-15.33-17.88-24.92-0.307-0.485-0.614-0.971-0.913-1.484-0.171-0.293-0.308-0.557-0.427-0.8a7.053 7.053 0 0 1-0.578-1.535 0.1 0.1 0 0 0-0.088-0.1 0.138 0.138 0 0 0-0.073 0c-5e-3 0-0.013 9e-3 -0.019 0.011s-0.019 0.011-0.028 0.015l0.015-0.026c-9.735 5.7-13.04 16.25-13.34 21.53a19.39 19.39 0 0 0-10.67 4.111 11.59 11.59 0 0 0-1-0.758 17.97 17.97 0 0 1-0.109-9.473 28.7 28.7 0 0 0-9.329 7.21h-0.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294 0.687 28.22 28.22 0 0 0-3.788 3.245 33.84 33.84 0 0 0-3.623 4.347v6e-3 -7e-3a32.73 32.73 0 0 0-5.2 11.74l-0.052 0.256c-0.073 0.341-0.336 2.049-0.381 2.42 0 0.029-6e-3 0.056-9e-3 0.085a36.94 36.94 0 0 0-0.629 5.343v0.2a38.76 38.76 0 0 0 76.95 6.554c0.065-0.5 0.118-0.995 0.176-1.5a39.86 39.86 0 0 0-2.514-19.47zm-44.67 30.34c0.181 0.087 0.351 0.181 0.537 0.264l0.027 0.017q-0.282-0.135-0.564-0.281zm8.878-23.38m31.95-4.934v-0.037l7e-3 0.041z"
                    fill="url(#a)"
                />
                <path
                    d="m74.62 26.83c-1.684-4.052-5.1-8.427-7.775-9.81a40.27 40.27 0 0 1 3.925 11.76v0.037l7e-3 0.041a35.1 35.1 0 0 1-1.206 26.16c-4.442 9.531-15.19 19.3-32.02 18.82-18.18-0.515-34.2-14.01-37.19-31.68-0.545-2.787 0-4.2 0.274-6.465a28.88 28.88 0 0 0-0.623 5.348v0.2a38.76 38.76 0 0 0 76.95 6.554c0.065-0.5 0.118-0.995 0.176-1.5a39.86 39.86 0 0 0-2.514-19.47z"
                    fill="url(#b)"
                />
                <path
                    d="m74.62 26.83c-1.684-4.052-5.1-8.427-7.775-9.81a40.27 40.27 0 0 1 3.925 11.76v0.037l7e-3 0.041a35.1 35.1 0 0 1-1.206 26.16c-4.442 9.531-15.19 19.3-32.02 18.82-18.18-0.515-34.2-14.01-37.19-31.68-0.545-2.787 0-4.2 0.274-6.465a28.88 28.88 0 0 0-0.623 5.348v0.2a38.76 38.76 0 0 0 76.95 6.554c0.065-0.5 0.118-0.995 0.176-1.5a39.86 39.86 0 0 0-2.514-19.47z"
                    fill="url(#c)"
                />
                <path
                    d="m55.78 31.38c0.084 0.059 0.162 0.118 0.241 0.177a21.1 21.1 0 0 0-3.6-4.695c-12.05-12.05-3.157-26.12-1.658-26.84l0.015-0.022c-9.735 5.7-13.04 16.25-13.34 21.53 0.452-0.031 0.9-0.069 1.362-0.069a19.56 19.56 0 0 1 16.98 9.917z"
                    fill="url(#d)"
                />
                <path
                    d="m38.82 33.79c-0.064 0.964-3.47 4.289-4.661 4.289-11.02 0-12.81 6.667-12.81 6.667 0.488 5.614 4.4 10.24 9.129 12.68 0.216 0.112 0.435 0.213 0.654 0.312q0.569 0.252 1.138 0.466a17.24 17.24 0 0 0 5.043 0.973c19.32 0.906 23.06-23.1 9.119-30.07a13.38 13.38 0 0 1 9.345 2.269 19.56 19.56 0 0 0-16.98-9.917c-0.46 0-0.91 0.038-1.362 0.069a19.39 19.39 0 0 0-10.67 4.111c0.591 0.5 1.258 1.168 2.663 2.553 2.63 2.591 9.375 5.275 9.39 5.59z"
                    fill="url(#e)"
                />
                <path
                    d="m38.82 33.79c-0.064 0.964-3.47 4.289-4.661 4.289-11.02 0-12.81 6.667-12.81 6.667 0.488 5.614 4.4 10.24 9.129 12.68 0.216 0.112 0.435 0.213 0.654 0.312q0.569 0.252 1.138 0.466a17.24 17.24 0 0 0 5.043 0.973c19.32 0.906 23.06-23.1 9.119-30.07a13.38 13.38 0 0 1 9.345 2.269 19.56 19.56 0 0 0-16.98-9.917c-0.46 0-0.91 0.038-1.362 0.069a19.39 19.39 0 0 0-10.67 4.111c0.591 0.5 1.258 1.168 2.663 2.553 2.63 2.591 9.375 5.275 9.39 5.59z"
                    fill="url(#f)"
                />
                <path
                    d="m24.96 24.36c0.314 0.2 0.573 0.374 0.8 0.531a17.97 17.97 0 0 1-0.109-9.473 28.7 28.7 0 0 0-9.329 7.21c0.189-5e-3 5.811-0.106 8.638 1.732z"
                    fill="url(#g)"
                />
                <path
                    d="m0.354 42.16c2.991 17.67 19.01 31.17 37.19 31.68 16.83 0.476 27.58-9.294 32.02-18.82a35.1 35.1 0 0 0 1.206-26.16v-0.037c0-0.029-6e-3 -0.046 0-0.037l7e-3 0.065c1.375 8.977-3.191 17.67-10.33 23.56l-0.022 0.05c-13.91 11.33-27.22 6.834-29.91 5q-0.282-0.135-0.564-0.281c-8.109-3.876-11.46-11.26-10.74-17.6a9.953 9.953 0 0 1-9.181-5.775 14.62 14.62 0 0 1 14.25-0.572 19.3 19.3 0 0 0 14.55 0.572c-0.015-0.315-6.76-3-9.39-5.59-1.405-1.385-2.072-2.052-2.663-2.553a11.59 11.59 0 0 0-1-0.758c-0.23-0.157-0.489-0.327-0.8-0.531-2.827-1.838-8.449-1.737-8.635-1.732h-0.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294 0.687 28.22 28.22 0 0 0-3.788 3.245 33.84 33.84 0 0 0-3.638 4.337v6e-3 -7e-3a32.73 32.73 0 0 0-5.2 11.74c-0.019 0.079-1.396 6.099-0.717 9.221z"
                    fill="url(#h)"
                />
                <path
                    d="m52.42 26.86a21.1 21.1 0 0 1 3.6 4.7c0.213 0.161 0.412 0.321 0.581 0.476 8.787 8.1 4.183 19.55 3.84 20.36 7.138-5.881 11.7-14.58 10.33-23.56-4.384-10.93-11.82-15.34-17.88-24.93-0.307-0.485-0.614-0.971-0.913-1.484-0.171-0.293-0.308-0.557-0.427-0.8a7.053 7.053 0 0 1-0.578-1.535 0.1 0.1 0 0 0-0.088-0.1 0.138 0.138 0 0 0-0.073 0c-5e-3 0-0.013 9e-3 -0.019 0.011s-0.019 0.011-0.028 0.015c-1.499 0.711-10.39 14.79 1.66 26.83z"
                    fill="url(#i)"
                />
                <path
                    d="m56.6 32.04c-0.169-0.155-0.368-0.315-0.581-0.476-0.079-0.059-0.157-0.118-0.241-0.177a13.38 13.38 0 0 0-9.345-2.269c13.94 6.97 10.2 30.97-9.119 30.07a17.24 17.24 0 0 1-5.043-0.973q-0.569-0.213-1.138-0.466c-0.219-0.1-0.438-0.2-0.654-0.312l0.027 0.017c2.694 1.839 16 6.332 29.91-5l0.022-0.05c0.347-0.81 4.951-12.26-3.84-20.36z"
                    fill="url(#j)"
                />
                <path
                    d="m21.35 44.74s1.789-6.667 12.81-6.667c1.191 0 4.6-3.325 4.661-4.289a19.3 19.3 0 0 1-14.55-0.572 14.62 14.62 0 0 0-14.25 0.572 9.953 9.953 0 0 0 9.181 5.775c-0.718 6.337 2.632 13.72 10.74 17.6 0.181 0.087 0.351 0.181 0.537 0.264-4.733-2.445-8.641-7.069-9.129-12.68z"
                    fill="url(#k)"
                />
                <path
                    d="m74.62 26.83c-1.684-4.052-5.1-8.427-7.775-9.81a40.27 40.27 0 0 1 3.925 11.76l7e-3 0.065c-4.382-10.92-11.81-15.33-17.88-24.92-0.307-0.485-0.614-0.971-0.913-1.484-0.171-0.293-0.308-0.557-0.427-0.8a7.053 7.053 0 0 1-0.578-1.535 0.1 0.1 0 0 0-0.088-0.1 0.138 0.138 0 0 0-0.073 0c-5e-3 0-0.013 9e-3 -0.019 0.011s-0.019 0.011-0.028 0.015l0.015-0.026c-9.735 5.7-13.04 16.25-13.34 21.53 0.452-0.031 0.9-0.069 1.362-0.069a19.56 19.56 0 0 1 16.98 9.917 13.38 13.38 0 0 0-9.345-2.269c13.94 6.97 10.2 30.97-9.119 30.07a17.24 17.24 0 0 1-5.043-0.973q-0.569-0.213-1.138-0.466c-0.219-0.1-0.438-0.2-0.654-0.312l0.027 0.017q-0.282-0.135-0.564-0.281c0.181 0.087 0.351 0.181 0.537 0.264-4.733-2.446-8.641-7.07-9.129-12.68 0 0 1.789-6.667 12.81-6.667 1.191 0 4.6-3.325 4.661-4.289-0.015-0.315-6.76-3-9.39-5.59-1.405-1.385-2.072-2.052-2.663-2.553a11.59 11.59 0 0 0-1-0.758 17.97 17.97 0 0 1-0.109-9.473 28.7 28.7 0 0 0-9.329 7.21h-0.018c-1.536-1.947-1.428-8.367-1.34-9.708a6.928 6.928 0 0 0-1.294 0.687 28.22 28.22 0 0 0-3.788 3.245 33.84 33.84 0 0 0-3.623 4.347v6e-3 -7e-3a32.73 32.73 0 0 0-5.2 11.74l-0.052 0.256c-0.073 0.341-0.4 2.073-0.447 2.445a45.09 45.09 0 0 0-0.572 5.403v0.2a38.76 38.76 0 0 0 76.95 6.554c0.065-0.5 0.118-0.995 0.176-1.5a39.86 39.86 0 0 0-2.514-19.47zm-3.845 1.991 7e-3 0.041z"
                    fill="url(#l)"
                />
            </g>
        </svg>
    )
}
