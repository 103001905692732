import { useEffect, useState } from 'react'

export default function hasSessionToken() {
    const [sessionToken, setSessionToken] = useState<string | null>(null)

    useEffect(() => {
        // Automatically log user in if they have a session token
        const getSessionToken = () => {
            const sessionToken = localStorage.getItem('sessionToken')
            if (sessionToken) setSessionToken(sessionToken)
        }
        getSessionToken()
    }, [])
    return sessionToken
}
