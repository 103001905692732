/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetUserProfilePaymentMethods200ResponseInner
 */
export interface GetUserProfilePaymentMethods200ResponseInner {
    /**
     * 
     * @type {string}
     * @memberof GetUserProfilePaymentMethods200ResponseInner
     */
    brand: string;
    /**
     * 
     * @type {number}
     * @memberof GetUserProfilePaymentMethods200ResponseInner
     */
    expiryMonth: number;
    /**
     * 
     * @type {number}
     * @memberof GetUserProfilePaymentMethods200ResponseInner
     */
    expiryYear: number;
    /**
     * 
     * @type {string}
     * @memberof GetUserProfilePaymentMethods200ResponseInner
     */
    lastFourDigits: string;
}

/**
 * Check if a given object implements the GetUserProfilePaymentMethods200ResponseInner interface.
 */
export function instanceOfGetUserProfilePaymentMethods200ResponseInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "brand" in value;
    isInstance = isInstance && "expiryMonth" in value;
    isInstance = isInstance && "expiryYear" in value;
    isInstance = isInstance && "lastFourDigits" in value;

    return isInstance;
}

export function GetUserProfilePaymentMethods200ResponseInnerFromJSON(json: any): GetUserProfilePaymentMethods200ResponseInner {
    return GetUserProfilePaymentMethods200ResponseInnerFromJSONTyped(json, false);
}

export function GetUserProfilePaymentMethods200ResponseInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUserProfilePaymentMethods200ResponseInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'brand': json['brand'],
        'expiryMonth': json['expiry-month'],
        'expiryYear': json['expiry-year'],
        'lastFourDigits': json['last_four_digits'],
    };
}

export function GetUserProfilePaymentMethods200ResponseInnerToJSON(value?: GetUserProfilePaymentMethods200ResponseInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'brand': value.brand,
        'expiry-month': value.expiryMonth,
        'expiry-year': value.expiryYear,
        'last_four_digits': value.lastFourDigits,
    };
}

