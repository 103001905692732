import {
    CREATOR,
    ONE_USER_TO_ADD,
    ORGANISATION_PRICE,
    PREMIUM_PRICE,
    ZERO_USER_TO_ADD,
} from '../constants/constants'
import SpinnerSvgIcon from '../icons/SpinnerSvgIcon'

interface BillingPlanProps {
    isLoading: boolean
    isEnterpriseFolder: boolean
    numberOfOrganisationMembers: number
    numberOfNonMemberEmailAddressesToAdd: number
    totalNumberOfUsersInOrg: number
    numberOfEmailAddressesToAdd: number
    isPremiumFolder?: boolean
    isFreeLimitExceeded?: boolean
    isBillingForShareFolder?: boolean
    numberOfFolderUsers?: number
}

export default function BillingPlan(props: BillingPlanProps) {
    const {
        isLoading,
        isEnterpriseFolder,
        numberOfOrganisationMembers,
        numberOfNonMemberEmailAddressesToAdd,
        totalNumberOfUsersInOrg,
        numberOfEmailAddressesToAdd,
        isFreeLimitExceeded,
        isPremiumFolder,
        isBillingForShareFolder,
        numberOfFolderUsers,
    } = props

    const renderPaymentDisplay = (users: number, price: number) => {
        return (
            <p>
                ${users * price}&nbsp;
                <span className="text-base text-slate-500">
                    ({users}&nbsp;
                    {users > ONE_USER_TO_ADD ? 'users' : 'user'} x ${price})
                </span>
            </p>
        )
    }

    if (isLoading)
        return (
            <div className="flex h-52 w-full items-center justify-center">
                <SpinnerSvgIcon height="40" width="40" />
            </div>
        )

    if (isEnterpriseFolder) {
        return (
            <>
                <h3 className="text-lg font-semibold">Enterprise Plan</h3>
                <p className="text-base text-slate-500">
                    Collaboration of an organisation
                </p>
                <div className="flex flex-col items-end pt-4">
                    {/* Get current payment and only display if that exists */}
                    <>
                        <div className="flex w-full justify-between text-lg">
                            <p className="font-semibold">
                                Current monthly cost
                            </p>
                            {renderPaymentDisplay(
                                numberOfOrganisationMembers,
                                ORGANISATION_PRICE
                            )}
                        </div>
                    </>

                    {numberOfNonMemberEmailAddressesToAdd > 0 && (
                        <>
                            <div className="flex w-full items-center justify-between text-lg">
                                <p className="font-semibold">
                                    New monthly cost
                                </p>
                                {renderPaymentDisplay(
                                    totalNumberOfUsersInOrg,
                                    ORGANISATION_PRICE
                                )}
                            </div>

                            <div className="mt-3 flex w-full items-center justify-between border-t border-slate-500 pt-3 text-lg">
                                {/* @todo: get the cost that the user will pay immediately */}
                                <p className="font-semibold">Cost today</p>
                                {renderPaymentDisplay(
                                    numberOfNonMemberEmailAddressesToAdd,
                                    ORGANISATION_PRICE
                                )}
                            </div>
                        </>
                    )}
                </div>
            </>
        )
    }

    if (
        //  Share Folder Billing, Premium
        numberOfEmailAddressesToAdd > ONE_USER_TO_ADD &&
        isBillingForShareFolder
    )
        return (
            <>
                <h3 className="text-lg font-semibold">Premium Plan</h3>
                <p className="text-base text-slate-500">
                    Applicable to 3 or more users in a shared folder, $
                    {PREMIUM_PRICE}
                    &nbsp;per user
                </p>

                {/* Breakdown of cost */}
                <div className="flex flex-col items-end pt-4">
                    {numberOfEmailAddressesToAdd > ZERO_USER_TO_ADD && (
                        <>
                            <div className="flex w-full items-center justify-between text-lg">
                                <p className="font-semibold">Monthly cost</p>

                                {/* Include creator */}
                                {renderPaymentDisplay(
                                    numberOfEmailAddressesToAdd + CREATOR,
                                    PREMIUM_PRICE
                                )}
                            </div>

                            <div className="mt-3 flex w-full items-center justify-between border-t border-slate-500 pt-3 text-lg">
                                <p className="font-semibold">Cost today</p>
                                {renderPaymentDisplay(
                                    numberOfEmailAddressesToAdd + CREATOR,
                                    PREMIUM_PRICE
                                )}
                            </div>
                        </>
                    )}
                </div>
            </>
        )

    if (
        // Invite Billing, Premium
        !isBillingForShareFolder &&
        (isFreeLimitExceeded || isPremiumFolder)
    ) {
        const numberOfTotalUsers =
            (numberOfFolderUsers ?? 0) + numberOfEmailAddressesToAdd
        return (
            <>
                <h3 className="text-lg font-semibold">Premium Plan</h3>
                <p className="text-base text-slate-500">
                    Applicable to 3 or more users in a shared folder, $
                    {PREMIUM_PRICE}
                    &nbsp;per user
                </p>

                {/* Breakdown of cost */}
                <div className="flex flex-col items-end pt-4">
                    {/* Get current payment and only display if that exists */}
                    {isPremiumFolder && (
                        <>
                            <div className="flex w-full justify-between text-lg">
                                <p className="font-semibold">
                                    Current monthly cost
                                </p>
                                <p>
                                    $
                                    {(numberOfFolderUsers ?? 0) * PREMIUM_PRICE}
                                    &nbsp;
                                    <span className="text-base text-slate-500">
                                        ({numberOfFolderUsers} users x $
                                        {PREMIUM_PRICE})
                                    </span>
                                </p>
                            </div>
                        </>
                    )}
                    {numberOfEmailAddressesToAdd > ZERO_USER_TO_ADD && (
                        <>
                            <div className="flex w-full items-center justify-between text-lg">
                                <p className="font-semibold">
                                    {isPremiumFolder
                                        ? 'New monthly cost'
                                        : 'Monthly cost'}
                                </p>
                                {renderPaymentDisplay(
                                    numberOfTotalUsers,
                                    PREMIUM_PRICE
                                )}
                            </div>

                            <div className="mt-3 flex w-full items-center justify-between border-t border-slate-500 pt-3 text-lg">
                                <p className="font-semibold">Cost today</p>
                                {/* If there is not already payment, add current folder users */}
                                {isPremiumFolder
                                    ? renderPaymentDisplay(
                                          numberOfEmailAddressesToAdd,
                                          PREMIUM_PRICE
                                      )
                                    : renderPaymentDisplay(
                                          numberOfTotalUsers,
                                          PREMIUM_PRICE
                                      )}
                            </div>
                        </>
                    )}
                </div>
            </>
        )
    }
}
