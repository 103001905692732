/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BookmarkNode } from './BookmarkNode';
import {
    BookmarkNodeFromJSON,
    BookmarkNodeFromJSONTyped,
    BookmarkNodeToJSON,
} from './BookmarkNode';
import type { PostSharedFolderRequestEncryption } from './PostSharedFolderRequestEncryption';
import {
    PostSharedFolderRequestEncryptionFromJSON,
    PostSharedFolderRequestEncryptionFromJSONTyped,
    PostSharedFolderRequestEncryptionToJSON,
} from './PostSharedFolderRequestEncryption';

/**
 * 
 * @export
 * @interface PostSharedFolderRequest
 */
export interface PostSharedFolderRequest {
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderRequest
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostSharedFolderRequest
     */
    invitedEmails?: Array<string>;
    /**
     * 
     * @type {PostSharedFolderRequestEncryption}
     * @memberof PostSharedFolderRequest
     */
    encryption?: PostSharedFolderRequestEncryption;
    /**
     * 
     * @type {number}
     * @memberof PostSharedFolderRequest
     */
    organisationId?: number;
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderRequest
     */
    hashWithoutIds: string;
    /**
     * 
     * @type {Array<BookmarkNode>}
     * @memberof PostSharedFolderRequest
     */
    bookmarkTree: Array<BookmarkNode>;
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderRequest
     */
    hashWithIds: string;
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderRequest
     */
    deviceId: string;
}

/**
 * Check if a given object implements the PostSharedFolderRequest interface.
 */
export function instanceOfPostSharedFolderRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "hashWithoutIds" in value;
    isInstance = isInstance && "bookmarkTree" in value;
    isInstance = isInstance && "hashWithIds" in value;
    isInstance = isInstance && "deviceId" in value;

    return isInstance;
}

export function PostSharedFolderRequestFromJSON(json: any): PostSharedFolderRequest {
    return PostSharedFolderRequestFromJSONTyped(json, false);
}

export function PostSharedFolderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSharedFolderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'invitedEmails': !exists(json, 'invited_emails') ? undefined : json['invited_emails'],
        'encryption': !exists(json, 'encryption') ? undefined : PostSharedFolderRequestEncryptionFromJSON(json['encryption']),
        'organisationId': !exists(json, 'organisation_id') ? undefined : json['organisation_id'],
        'hashWithoutIds': json['hash_without_ids'],
        'bookmarkTree': ((json['bookmark_tree'] as Array<any>).map(BookmarkNodeFromJSON)),
        'hashWithIds': json['hash_with_ids'],
        'deviceId': json['device_id'],
    };
}

export function PostSharedFolderRequestToJSON(value?: PostSharedFolderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'invited_emails': value.invitedEmails,
        'encryption': PostSharedFolderRequestEncryptionToJSON(value.encryption),
        'organisation_id': value.organisationId,
        'hash_without_ids': value.hashWithoutIds,
        'bookmark_tree': ((value.bookmarkTree as Array<any>).map(BookmarkNodeToJSON)),
        'hash_with_ids': value.hashWithIds,
        'device_id': value.deviceId,
    };
}

