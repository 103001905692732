import { RadioGroup } from '@headlessui/react'
import { UserPlusIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/outline'
import { ShareType } from '../helpers/constants/enum'
import BrowserIconsLined from '../helpers/icons/BrowserIconsLined'

const shareTypeRadioOptions = [
    {
        value: ShareType.share,
        title: 'Share with others',
    },
    {
        value: ShareType.sync,
        title: 'Share across my browsers',
    },
    {
        value: ShareType.link,
        title: 'Export to webpage',
    },
]

interface ShareRadioButtonsProps {
    shareOption: string | null
    isLoading: boolean
    formErrorShareOption: string | undefined
    handleShareOptionSelect: (shareOption: ShareType) => void
}

export default function ShareRadioButtons(props: ShareRadioButtonsProps) {
    const {
        shareOption,
        isLoading,
        formErrorShareOption,
        handleShareOptionSelect,
    } = props
    return (
        <RadioGroup
            value={shareOption}
            onChange={handleShareOptionSelect}
            disabled={isLoading}
        >
            <RadioGroup.Label className="sr-only">
                Share Options
            </RadioGroup.Label>
            <>
                <div className="flex flex-col items-center md:flex-row justify-between">
                    {shareTypeRadioOptions.map((option) => (
                        <RadioGroup.Option
                            key={option.value}
                            value={option.value}
                            className={({ checked }) =>
                                `my-2 h-48 md:h-56 w-full md:w-1/3 cursor-pointer rounded py-8 px-4 hover:ring-2 hover:ring-primary active:ring-2 active:ring-primary disabled:cursor-not-allowed mr-3 last:mr-0 lg:my-0 focus:ring-2 focus:ring-primary focus:outline-none ${
                                    checked
                                        ? 'bg-primary-light bg-opacity-25  ring-2 ring-primary'
                                        : 'bg-white ring-1 ring-slate-300'
                                }
                                        
                                        ${
                                            isLoading &&
                                            'ring-1 hover:cursor-not-allowed hover:ring-1 hover:ring-slate-300 active:ring-1 active:ring-slate-300'
                                        }`
                            }
                        >
                            {({ checked }) => (
                                <div
                                    className={
                                        'flex h-full select-none items-center justify-between'
                                    }
                                >
                                    <div className="flex h-full flex-col justify-between w-full">
                                        <div className="flex items-center md:justify-start justify-center lg:justify-center">
                                            <div
                                                className={`h-6 min-w-[24px]  rounded-full ${
                                                    checked
                                                        ? 'border-4 border-primary'
                                                        : 'border border-slate-300'
                                                }`}
                                            />
                                            <RadioGroup.Label
                                                as="p"
                                                className={`${
                                                    checked
                                                        ? 'text-primary'
                                                        : ''
                                                } text-lg font-semibold pl-4`}
                                            >
                                                {option.title}
                                            </RadioGroup.Label>
                                        </div>

                                        <div className="flex w-full justify-center">
                                            <div className="flex h-full w-16 flex-col justify-center">
                                                {option.value ===
                                                    ShareType.share && (
                                                    <UserPlusIcon
                                                        className={`max-h -24  stroke-[0.75] ${
                                                            checked &&
                                                            'stroke-primary'
                                                        }`}
                                                    />
                                                )}
                                                {option.value ===
                                                    ShareType.sync && (
                                                    <BrowserIconsLined
                                                        stroke={
                                                            checked
                                                                ? '#4338ca'
                                                                : '#334155'
                                                        }
                                                    />
                                                )}
                                                {option.value ===
                                                    ShareType.link && (
                                                    <ArrowUpOnSquareIcon
                                                        className={`max-h -24  stroke-[0.75] ${
                                                            checked &&
                                                            'stroke-primary'
                                                        }`}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </RadioGroup.Option>
                    ))}
                </div>
            </>
        </RadioGroup>
    )
}
