import Section from '../helpers/commonComponents/Section'
import { MAIL_TO_EMAIL } from '../helpers/constants/constants'
import { ButtonTheme } from '../helpers/constants/enum'
import LinkCTA from '../helpers/customComponents/Link'

export default function DeleteAccount() {
    return (
        <Section className="box-border w-full sm:w-96 md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-40">
            <>
                <p className="mb-4 text-center text-lg font-bold md:text-left">
                    Want to delete your account?
                </p>
                <p className="text-center text-lg md:text-left">
                    We delete accounts within 48hrs upon receiving an email
                    request. All personal data will be deleted from our system.
                    You can send an email to&nbsp;
                    <span className="select-text font-semibold">
                        support@bookmarkllama.com
                    </span>
                    .
                </p>
                <div className="flex justify-end">
                    <LinkCTA
                        buttonText="Delete account"
                        buttonTheme={ButtonTheme.primary}
                        mailTo={`${MAIL_TO_EMAIL}?subject=Account Deletion Request`}
                        className="mt-20 w-full md:max-w-max md:px-8"
                    />
                </div>
            </>
        </Section>
    )
}
