import { FormEvent, useState } from 'react'
import Modal from '../helpers/commonComponents/Modal'
import { ButtonTheme } from '../helpers/constants/enum'
import Button, { ButtonType } from '../helpers/customComponents/Button'
import { useCurrentSharedFolder, useCurrentExtensionState } from './Settings'
import apiHelper from '../apiClient/defaultApiClient'
import { useNavigate } from 'react-router-dom'
import { NotificationType } from '../helpers/constants/enum'
import Section from '../helpers/commonComponents/Section'
import { MAIL_TO_EMAIL } from '../helpers/constants/constants'
import LinkCTA from '../helpers/customComponents/Link'
import { runSyncEngine } from '../extensionCommunication/messager'
import SpinnerSvgIcon from '../helpers/icons/SpinnerSvgIcon'
import { uiText } from '../uiText/uiText'
import { toastError } from '../helpers/commonComponents/toastHelper'

export default function StopSharing() {
    const [open, setOpen] = useState<boolean>(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const { extensionState } = useCurrentExtensionState()
    const { currentFolder } = useCurrentSharedFolder()
    const navigate = useNavigate()

    async function stopSharingFolder(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()

        if (currentFolder) {
            setIsLoading(true)
            setOpen(!open)

            await apiHelper
                .deleteSharedFolderById({
                    folderID: currentFolder.id,
                })
                .then(async () => {
                    if (extensionState?.isInstalled) {
                        await runSyncEngine()
                    }
                })
                .then(() => {
                    navigate('/', {
                        state: {
                            notification:
                                NotificationType.stopSharingFolderSuccess,
                        },
                    })
                })
                .catch(() => {
                    setIsLoading(false)
                    toastError(uiText.Notifications.error.stopSharing)
                })
        }
    }
    return (
        <Section className="mt-8 box-border w-full sm:w-96 md:mt-0 md:w-[80%] md:px-8 lg:ml-0 xl:px-40">
            {isLoading ? (
                <div className="flex w-full items-center justify-center">
                    <SpinnerSvgIcon height="40" width="40" />
                </div>
            ) : (
                <>
                    <div className=" box-border w-full text-lg">
                        <p className="text-center font-bold md:text-left">
                            Stop sharing your folder
                        </p>
                        <ul className="mt-6 ml-6 list-disc">
                            <li>Your folder will not continue to sync</li>
                            <li>
                                Your invitees will be removed from your folder
                            </li>
                        </ul>
                        <div className="mt-20 flex w-full justify-center md:justify-end">
                            <Button
                                buttonText="Stop sharing folder"
                                disabled={false}
                                buttonTheme={ButtonTheme.errorSecondary}
                                className="w-full md:max-w-max md:px-8"
                                onClick={() => setOpen(!open)}
                            />
                        </div>
                    </div>
                    <Modal
                        open={open}
                        setOpen={setOpen}
                        onSubmit={
                            currentFolder?.isPremium
                                ? undefined
                                : (e: FormEvent<HTMLFormElement>) =>
                                      stopSharingFolder(e)
                        }
                        modalTitle={
                            currentFolder?.isPremium
                                ? 'Send us an email'
                                : 'Are you sure you want to stop sharing your folder?'
                        }
                        modalText={
                            currentFolder?.isPremium
                                ? "It looks like you're trying to stop sharing a premium folder, please send us an email and we'll take care of it"
                                : "If you stop sharing your folder, others won't be able to see it anymore."
                        }
                        action={
                            currentFolder?.isPremium
                                ? `${MAIL_TO_EMAIL}?subject=Stop Sharing Folder`
                                : undefined
                        }
                        primaryButton={
                            currentFolder?.isPremium ? (
                                <LinkCTA
                                    buttonText={'Send Email'}
                                    buttonTheme={ButtonTheme.primary}
                                    className="px-8"
                                    mailTo={`${MAIL_TO_EMAIL}?subject=Stop Sharing Folder`}
                                />
                            ) : (
                                <Button
                                    buttonType={ButtonType.submit}
                                    buttonText="Stop sharing folder"
                                    buttonTheme={ButtonTheme.errorPrimary}
                                    disabled={isLoading}
                                    className="px-8"
                                />
                            )
                        }
                        secondaryButton={
                            <Button
                                buttonType={ButtonType.button}
                                buttonText="Cancel"
                                buttonTheme={ButtonTheme.tertiary}
                                disabled={isLoading}
                                className="mt-2 px-8 sm:mr-4 sm:mt-0"
                                onClick={() => setOpen(!open)}
                            />
                        }
                    />
                </>
            )}
        </Section>
    )
}
