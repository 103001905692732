interface BookmarkLlamaSvgIconProps {
    height?: string
    width?: string
    className?: string
}

export default function BookmarkLlamaSvgIcon(props: BookmarkLlamaSvgIconProps) {
    const { height, width, className } = props
    return (
        <svg
            width={width || '26px'}
            height={height || '32px'}
            viewBox="0 0 26 42"
            xmlns="http://www.w3.org/2000/svg"
            className={`${className} fill-primary`}
        >
            <path
                d="M0 2C0 0.89543 0.895431 0 2 0H24C25.1046 0 26 0.895431 26 2V29.2556C26 31.1508 23.6095 31.9831 22.4325 30.4977L14.5675 20.572C13.7668 19.5615 12.2332 19.5615 11.4325 20.572L3.56755 30.4977C2.39052 31.9831 0 31.1508 0 29.2556V2Z"
                className="fill-primary"
            />
        </svg>
    )
}
