import { DefaultApi } from './apis/DefaultApi'
import { Configuration, FetchParams, ResponseContext } from './'
// import browser from "webextension-polyfill";

export class ApiMiddleware implements ApiMiddleware {
    public async pre(context: ResponseContext): Promise<FetchParams | void> {
        const accessToken = await this.acquireToken()
        return {
            url: context.url,
            init: {
                ...context.init,
                headers: accessToken
                    ? new Headers({
                          ...context.init.headers,
                          Authorization: `Bearer ${accessToken}`,
                      })
                    : context.init.headers,
            },
        }
    }

    public post(context: ResponseContext): Promise<Response | void> {
        return Promise.resolve(context.response)
    }

    private async acquireToken(): Promise<string | null> {
        const sessionToken = localStorage.getItem('sessionToken')
        if (!sessionToken || sessionToken === '') {
            return null
        }
        return sessionToken as string
    }
}

const config = new Configuration({
    basePath: import.meta.env.VITE_API_URL,
    middleware: [new ApiMiddleware()],
})

export default new DefaultApi(config)
