/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostSharedFolderValidatePasswordRequest
 */
export interface PostSharedFolderValidatePasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof PostSharedFolderValidatePasswordRequest
     */
    passwordHash?: string;
}

/**
 * Check if a given object implements the PostSharedFolderValidatePasswordRequest interface.
 */
export function instanceOfPostSharedFolderValidatePasswordRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PostSharedFolderValidatePasswordRequestFromJSON(json: any): PostSharedFolderValidatePasswordRequest {
    return PostSharedFolderValidatePasswordRequestFromJSONTyped(json, false);
}

export function PostSharedFolderValidatePasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostSharedFolderValidatePasswordRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'passwordHash': !exists(json, 'password_hash') ? undefined : json['password_hash'],
    };
}

export function PostSharedFolderValidatePasswordRequestToJSON(value?: PostSharedFolderValidatePasswordRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'password_hash': value.passwordHash,
    };
}

