import { Link, Navigate } from 'react-router-dom'
import BookmarkLlamaDesktopIcon from '../customComponents/BookmarkLlamaDesktopIcon'
import BookmarkLlamaSvgIcon from '../icons/BookmarkLlamaSvgIcon'
import { XMarkIcon } from '@heroicons/react/24/solid'

interface NavigationProps {
    pageTitle?: string
    children?: JSX.Element
    showLink?: boolean
}

export default function Navigation(props: NavigationProps) {
    const { pageTitle, children, showLink = false } = props
    return (
        <nav className="box-border flex w-full items-center  justify-between  ">
            <div className="flex w-[90%] items-center">
                <a href="/">
                    <BookmarkLlamaDesktopIcon className="hidden md:flex" />
                    <BookmarkLlamaSvgIcon className="mt-3 h-10 w-10 md:hidden" />
                </a>
                <h1 className="mask-text w-[85%] select-none truncate text-ellipsis pl-4 text-lg font-medium md:pl-10 md:text-2xl xl:pl-48">
                    {pageTitle}
                </h1>
            </div>

            <div className=" flex w-max items-center justify-around">
                {children}

                {showLink && (
                    <Link to="/" state={{}}>
                        <XMarkIcon
                            className="h-10 w-10 rounded-full hover:bg-slate-100 active:ring-1 active:ring-slate-500"
                            aria-hidden="true"
                        />
                    </Link>
                )}
            </div>
        </nav>
    )
}
