import Section from '../helpers/commonComponents/Section'
import { RadioGroup } from '@headlessui/react'
import { FolderOwnershipValues } from '../helpers/constants/enum'
import { Organisation } from '../apiClient'
import { UserGroupIcon, UserIcon } from '@heroicons/react/24/outline'

interface FolderOwnershipRadioButtonsProps {
    folderOwnershipOption: string | undefined
    isLoading: boolean
    formErrorFolderOwnershipOption: string | undefined
    organisation: Organisation | null
    handleFolderOwnershipSelect: (
        folderOwnershipValue: FolderOwnershipValues
    ) => void
}

export default function FolderOwnerShipRadioButtons(
    props: FolderOwnershipRadioButtonsProps
) {
    const {
        folderOwnershipOption,
        isLoading,
        formErrorFolderOwnershipOption,
        organisation,
        handleFolderOwnershipSelect,
    } = props

    const folderOwnership = [
        {
            value: FolderOwnershipValues.organisation,
            title: organisation?.name ?? 'Organisation',
            svg: (
                <UserGroupIcon
                    className="max-h-8 stroke-1"
                    aria-hidden="true"
                />
            ),
        },
        {
            value: FolderOwnershipValues.personal,
            title: 'Personal',
            svg: <UserIcon className="max-h-7 stroke-1" aria-hidden="true" />,
        },
    ]

    return (
        <Section
            sectionTitle="Who's the folder for?"
            error={formErrorFolderOwnershipOption}
        >
            <>
                <RadioGroup
                    value={folderOwnershipOption}
                    onChange={handleFolderOwnershipSelect}
                    disabled={isLoading}
                >
                    <RadioGroup.Label className="sr-only">
                        Folder Ownership
                    </RadioGroup.Label>
                    <>
                        <div className="flex flex-col items-center md:flex-row md:justify-center lg:justify-between">
                            {folderOwnership.map((option) => (
                                <RadioGroup.Option
                                    key={option.value}
                                    value={option.value}
                                    className={({ checked }) =>
                                        `my-2 h-24 w-full cursor-pointer  rounded p-8 hover:ring-2 hover:ring-primary active:ring-2 active:ring-primary md:w-2/4 md:first:mr-6 lg:m-0 ${
                                            checked
                                                ? 'bg-primary-light bg-opacity-25 ring-2 ring-primary'
                                                : 'bg-white ring-1 ring-slate-300'
                                        }
                                        
                                        ${
                                            isLoading &&
                                            'ring-1 hover:cursor-not-allowed hover:ring-1 hover:ring-slate-300 active:ring-1 active:ring-slate-300'
                                        }`
                                    }
                                >
                                    {({ checked }) => (
                                        <div className="flex h-full items-center justify-center">
                                            <RadioGroup.Label
                                                as="p"
                                                className={`${
                                                    checked
                                                        ? 'text-primary'
                                                        : ''
                                                } max-w-[300px] truncate text-ellipsis text-lg font-bold`}
                                            >
                                                {option.title}
                                            </RadioGroup.Label>
                                            <div
                                                className={`${
                                                    checked
                                                        ? 'text-primary'
                                                        : ''
                                                } mx-2 w-10`}
                                            >
                                                {option.svg}
                                            </div>
                                        </div>
                                    )}
                                </RadioGroup.Option>
                            ))}
                        </div>
                    </>
                </RadioGroup>
            </>
        </Section>
    )
}
