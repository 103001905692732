interface SpinnerSvgIconProps {
    height?: string
    width?: string
    className?: string
    spinnerColor?: string
    fill?: string
}

export default function SpinnerSvgIcon(props: SpinnerSvgIconProps) {
    const { height, width, className, spinnerColor, fill } = props
    return (
        <svg
            width={width || '24'}
            height={height || '24'}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={` ${
                spinnerColor ? `fill-[${spinnerColor}]` : `fill-primary`
            } ${className} animate-spin `}
        >
            <path
                d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM2.39991 8C2.39991 11.0928 4.90716 13.6001 8 13.6001C11.0928 13.6001 13.6001 11.0928 13.6001 8C13.6001 4.90716 11.0928 2.39991 8 2.39991C4.90716 2.39991 2.39991 4.90716 2.39991 8Z"
                className={
                    spinnerColor ? `fill-[${spinnerColor}]` : `fill-primary`
                }
            />
            <path
                d="M16 8C16 6.94943 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05057 -4.59221e-08 8 0L8 2.4C8.7354 2.4 9.4636 2.54485 10.143 2.82627C10.8225 3.1077 11.4398 3.52019 11.9598 4.0402C12.4798 4.56021 12.8923 5.17755 13.1737 5.85697C13.4552 6.5364 13.6 7.2646 13.6 8H16Z"
                fill={fill ?? '#FAFAFA'}
            />
        </svg>
    )
}
