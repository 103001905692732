/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateBookmark } from './CreateBookmark';
import {
    CreateBookmarkFromJSON,
    CreateBookmarkFromJSONTyped,
    CreateBookmarkToJSON,
} from './CreateBookmark';
import type { MoveBookmark } from './MoveBookmark';
import {
    MoveBookmarkFromJSON,
    MoveBookmarkFromJSONTyped,
    MoveBookmarkToJSON,
} from './MoveBookmark';
import type { RemoveBookmark } from './RemoveBookmark';
import {
    RemoveBookmarkFromJSON,
    RemoveBookmarkFromJSONTyped,
    RemoveBookmarkToJSON,
} from './RemoveBookmark';
import type { UpdateBookmark } from './UpdateBookmark';
import {
    UpdateBookmarkFromJSON,
    UpdateBookmarkFromJSONTyped,
    UpdateBookmarkToJSON,
} from './UpdateBookmark';

/**
 * 
 * @export
 * @interface SharedFolderChangelog
 */
export interface SharedFolderChangelog {
    /**
     * 
     * @type {number}
     * @memberof SharedFolderChangelog
     */
    version: number;
    /**
     * 
     * @type {Array<MoveBookmark>}
     * @memberof SharedFolderChangelog
     */
    moves: Array<MoveBookmark>;
    /**
     * 
     * @type {Array<RemoveBookmark>}
     * @memberof SharedFolderChangelog
     */
    removes: Array<RemoveBookmark>;
    /**
     * 
     * @type {Array<CreateBookmark>}
     * @memberof SharedFolderChangelog
     */
    creations: Array<CreateBookmark>;
    /**
     * 
     * @type {Array<UpdateBookmark>}
     * @memberof SharedFolderChangelog
     */
    updates: Array<UpdateBookmark>;
    /**
     * 
     * @type {string}
     * @memberof SharedFolderChangelog
     */
    hash: string;
    /**
     * 
     * @type {string}
     * @memberof SharedFolderChangelog
     */
    rawTreeHash?: string;
}

/**
 * Check if a given object implements the SharedFolderChangelog interface.
 */
export function instanceOfSharedFolderChangelog(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "version" in value;
    isInstance = isInstance && "moves" in value;
    isInstance = isInstance && "removes" in value;
    isInstance = isInstance && "creations" in value;
    isInstance = isInstance && "updates" in value;
    isInstance = isInstance && "hash" in value;

    return isInstance;
}

export function SharedFolderChangelogFromJSON(json: any): SharedFolderChangelog {
    return SharedFolderChangelogFromJSONTyped(json, false);
}

export function SharedFolderChangelogFromJSONTyped(json: any, ignoreDiscriminator: boolean): SharedFolderChangelog {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'version': json['version'],
        'moves': ((json['moves'] as Array<any>).map(MoveBookmarkFromJSON)),
        'removes': ((json['removes'] as Array<any>).map(RemoveBookmarkFromJSON)),
        'creations': ((json['creations'] as Array<any>).map(CreateBookmarkFromJSON)),
        'updates': ((json['updates'] as Array<any>).map(UpdateBookmarkFromJSON)),
        'hash': json['hash'],
        'rawTreeHash': !exists(json, 'raw_tree_hash') ? undefined : json['raw_tree_hash'],
    };
}

export function SharedFolderChangelogToJSON(value?: SharedFolderChangelog | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'version': value.version,
        'moves': ((value.moves as Array<any>).map(MoveBookmarkToJSON)),
        'removes': ((value.removes as Array<any>).map(RemoveBookmarkToJSON)),
        'creations': ((value.creations as Array<any>).map(CreateBookmarkToJSON)),
        'updates': ((value.updates as Array<any>).map(UpdateBookmarkToJSON)),
        'hash': value.hash,
        'raw_tree_hash': value.rawTreeHash,
    };
}

