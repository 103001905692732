/* tslint:disable */
/* eslint-disable */
/**
 * BookmarkLlama
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PutUserByIdRequest
 */
export interface PutUserByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof PutUserByIdRequest
     */
    newPassword?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PutUserByIdRequest
     */
    setAdmin?: boolean | null;
}

/**
 * Check if a given object implements the PutUserByIdRequest interface.
 */
export function instanceOfPutUserByIdRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PutUserByIdRequestFromJSON(json: any): PutUserByIdRequest {
    return PutUserByIdRequestFromJSONTyped(json, false);
}

export function PutUserByIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PutUserByIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newPassword': !exists(json, 'new_password') ? undefined : json['new_password'],
        'setAdmin': !exists(json, 'set_admin') ? undefined : json['set_admin'],
    };
}

export function PutUserByIdRequestToJSON(value?: PutUserByIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'new_password': value.newPassword,
        'set_admin': value.setAdmin,
    };
}

