import { useNavigate, useRouteError } from 'react-router-dom'
import { ButtonTheme } from '../constants/enum'
import Button from '../customComponents/Button'
import errorImg from '../../assets/error.svg'

interface ErrorPage {
    errorText?: string | null
}

type ErrorResponse = {
    data: any
    status: number
    statusText: string
    internal?: boolean
}

export default function ErrorPage(props: ErrorPage) {
    const error = useRouteError() as ErrorResponse
    const navigate = useNavigate()
    const { errorText } = props

    return (
        <div className="border-box flex h-full w-full flex-col-reverse items-center justify-center p-4 pb-10 lg:flex-row lg:p-14">
            <div className="relative flex h-full w-full items-center justify-center rounded-3xl lg:w-[45%]">
                <div className="absolute inset-0 bg-white bg-opacity-40"></div>
                <div
                    className="h-full w-full rounded-3xl bg-contain bg-no-repeat md:pt-10"
                    style={{
                        backgroundImage: `url('${errorImg}')`,
                        transform: 'scaleX(-1)',
                    }}
                ></div>
            </div>
            <div className="flex h-full w-full flex-col items-center justify-center md:w-[55%] md:px-4 md:pb-48 lg:w-[45%]">
                <h2 className="text-5xl font-black text-primary">Oops!</h2>
                <p className="mt-2 mb-20 text-xl font-semibold">
                    Something went wrong...
                </p>
                {errorText && <p className="py-8 text-lg">{errorText}</p>}
                {error && error.status && (
                    <p className="py-8 text-lg">
                        {error.status}
                        {error.statusText && ':'} {error.statusText}
                    </p>
                )}
                <Button
                    buttonTheme={ButtonTheme.primary}
                    buttonText="Go to homepage"
                    onClick={() => navigate('/')}
                    className="w-full sm:max-w-max sm:px-20 md:w-full lg:max-w-max lg:px-20"
                />
            </div>
        </div>
    )
}
