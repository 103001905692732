import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import posthog, { Properties } from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import '@fontsource/mulish/400.css'
import '@fontsource/mulish/500.css'
import '@fontsource/mulish/600.css'
import '@fontsource/mulish/700.css'
import '@fontsource/mulish/800.css'
import '@fontsource/mulish/600-italic.css'
import '@fontsource/montserrat-alternates/500.css'
import '@fontsource/montserrat-alternates/700.css'

import './index.css'

import { router } from './App'
import ErrorPage from './helpers/commonComponents/ErrorPage'
import * as Sentry from '@sentry/react'
import { posthogEnabled, posthogToken, posthogURL } from './helpers/posthog'
import apiHelper from './apiClient/defaultApiClient'
import { Toaster } from 'react-hot-toast'

let __COMMIT_HASH__: string = ''
const CommitHash = (window as any).__COMMIT_HASH__ || __COMMIT_HASH__

Sentry.init({
    dsn: 'https://9517dfcda5974645ae20a650956057f6@o1159175.ingest.sentry.io/4505361352097792',
    integrations: [new Sentry.Integrations.Dedupe()],
    maxValueLength: 999999,
    //This value is injected in at build time from vite config
    release: CommitHash,
    environment: import.meta.env.MODE,
    sampleRate: 1.0,

    beforeSend: async (event) => {
        //if not production/staging, don't send anything to sentry
        if (
            import.meta.env.MODE !== 'production' &&
            import.meta.env.MODE !== 'staging'
        ) {
            return null
        }
        return event
    },
})
if (posthogEnabled) {
    apiHelper.getDailyIdentifier().then((uniqueIdentifier) => {
        posthog.init(posthogToken, {
            api_host: posthogURL,
            ip: false,
            persistence: 'memory',
            session_recording: {
                maskTextSelector: '.mask-text',
            },
            enable_recording_console_log: false,
            sanitize_properties: (
                properties: Properties,
                event_name: string
            ): Properties => {
                const currentURL = properties['$current_url']
                if (currentURL) {
                    const url = new URL(currentURL as string)
                    const params = url.searchParams
                    const blackListedParams = [
                        'email',
                        'token',
                        'email_address',
                        'reset_token',
                    ]
                    blackListedParams.forEach((blackListParam) => {
                        if (params.has(blackListParam)) {
                            params.set(blackListParam, '*removed*')
                        }
                        for (let key of params.keys()) {
                            if (key.toLowerCase().includes(blackListParam)) {
                                params.set(key, '*removed*')
                            }
                        }
                    })
                    url.search = params.toString()
                    properties['$current_url'] = url.toString()
                }
                return properties
            },
            get_device_id: (uuid): string => {
                return `guest_${uniqueIdentifier.identifier}`
            },
        })
    })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <React.StrictMode>
        <PostHogProvider client={posthog}>
            <div>
                <Toaster position="top-right" />
            </div>
            <RouterProvider router={router} fallbackElement={<ErrorPage />} />
        </PostHogProvider>
    </React.StrictMode>
)
